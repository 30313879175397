import {Button, Card, Col, Drawer, List, Row} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpRequest} from "../services/httpRequest";
import dayjs from "dayjs";
import {SurveyEditor} from "./SurveyEditor";
import {toast} from "react-toastify";
import {SurveySend} from "./SurveySend";
import {SurveyResults} from "./SurveyResults";

export const Surveys = ({history}) => {
    const [surveys, setSurveys] = useState([]);
    const [surveyId, setSurveyId] = useState(null);
    const [surveyRespondents, setSurveyRespondents] = useState([]);
    const [noRespondents, setNoRespondents] = useState(null);
    const [survey, setSurvey] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);

    useEffect(() => {
        axios.get(`/api/v1/surveys`,
            httpRequest.getHeaders())
            .then(response => {
                setSurveys(response.data);
            })
            .catch(error => httpRequest.errorHandler(error, history));
    }, []);

    const editSurvey = (id: any) => {
        axios.get(`/api/v1/surveys/${id}`,
            httpRequest.getHeaders())
            .then(response => {
                setSurvey(response.data);
            })
            .catch(error => httpRequest.errorHandler(error, history));
    }

    const getSurveyRespondents = (id: any) => {
        setSurveyId(id);
        axios.get(`/api/v1/surveys/${id}/respondents`,
            httpRequest.getHeaders())
            .then(response => {
                setSurveyRespondents(response.data);
                if (!response.data.length) {
                    setNoRespondents({error: 'No users available to send to.'});
                }
            })
            .catch(error => httpRequest.errorHandler(error, history));
    }

    const save = () => {
        setLoading(true);
        axios.post(`/api/v1/surveys/${survey.id}`,
            {survey}, httpRequest.getHeaders())
            .then((response) => {
                toast.success('Survey has been successfully edited.');
            }).catch(error => httpRequest.errorHandler(error, history))
            .finally(() => setLoading(false));
    }

    const sendSurvey = () => {
        setLoading(true);
        axios.post(`/api/v1/surveys/${surveyId}/send`,
            {respondents: surveyRespondents.filter((respondent: any) => !respondent.disabled)}, httpRequest.getHeaders())
            .then((response: any) => {
                toast.success('Survey has been sent!');
                setSurveyRespondents([]);
                setSurveyId(null);
                setSurveys(surveys.map((survey: any) => survey.id === response.data.id ? {
                    ...survey,
                    last_sent: response.data.last_sent
                } : survey));
            }).catch(error => httpRequest.errorHandler(error, history))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (noRespondents) {
            toast.error(noRespondents.error);
        }
    }, [noRespondents]);

    return (
        <Col xs={24} className={'mt-3'}>
            <Card title={'Youth Surveys'}>
                <List dataSource={surveys}
                      renderItem={(item, index) => (
                          <List.Item key={index} actions={[<Button onClick={() => getSurveyRespondents(item.id)}
                                                                     disabled={item.last_sent && dayjs(item.last_sent).isAfter(dayjs().startOf('day'))}
                                                                     type={'primary'}>Send</Button>,
                              <Button disabled={item.last_sent} onClick={() => editSurvey(item.id)}>Edit</Button>,
                              <Button disabled={!item.responses.length} onClick={() => setResults(item.responses)}
                                      type={'primary'} ghost>Results</Button>]}>
                              <List.Item.Meta
                                  title={item.name}
                                  description={item.description}
                              />
                              <Row>Last
                                  sent: {item.last_sent ? dayjs(item.last_sent).format('MMM DD, YYYY') : 'Never'}</Row>
                          </List.Item>
                      )}
                />
                {results.length > 0 &&
                    <SurveyResults results={results}/>
                }
            </Card>
            <Drawer title={'Edit Survey Questions'} width={'50%'} onClose={() => setSurvey({})} open={survey.id}>
                <SurveyEditor survey={survey} setSurvey={setSurvey}/>
                <Button disabled={loading} onClick={() => save()} type={'primary'}>Save</Button>
            </Drawer>
            <Drawer title={'Send Survey'} width={'50%'} onClose={() => setSurveyRespondents([])}
                    open={surveyRespondents.length > 0}>
                <SurveySend surveyId={surveyId} respondents={surveyRespondents}
                            setSurveyRespondents={setSurveyRespondents}/>
                <Button className={'mt-3'}
                        disabled={loading || surveyRespondents.filter((resp: any) => !resp.disabled).length < 1}
                        onClick={() => sendSurvey()}
                        type={'primary'}>Send</Button>
            </Drawer>
        </Col>
    )
}