import React, {Component} from 'react';
import {httpRequest} from "../services/httpRequest";
import axios from "axios";
import {toast} from "react-toastify";

class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...httpRequest.getUser(),
            password: "",
            password_confirmation: "",
            disabled: true
        }

        this.passConRef = React.createRef();
    }

    update() {
        this.setState({
            disabled: true
        });
        if (this.state.password !== this.state.password_confirmation) {
            return;
        }

        let data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
        };

        if (this.state.password) {
            data = {
                ...data,
                password: this.state.password
            }
        }

        axios.post(`/api/v1/user/${this.state.id}`,
            {user: data},
            httpRequest.getHeaders())
            .then(response => {
                sessionStorage.setItem('user', JSON.stringify(response.data));
                toast.success('User successfully updated!')
            })
            .catch(error => {
                httpRequest.errorHandler(error, this.props.history)
            });
    }

    updateField(e, state) {
        if (state === 'password_confirmation') {
            if (this.state.password !== e.target.value) {
                this.passConRef.current.style.display = 'block';
            } else {
                this.passConRef.current.style.display = 'none';
            }
        }

        if (state === 'password') {
            if (this.state.password_confirmation !== e.target.value) {
                this.passConRef.current.style.display = 'block';
            } else {
                this.passConRef.current.style.display = 'none';
            }
        }

        this.setState({
            [state]: e.target.value,
            disabled: false,
        });
    }

    render() {
        return (
            <div className="col-12 mt-2">
                <form className="row needs-validation" onSubmit={(e) => e.preventDefault()} noValidate>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" className="form-control" aria-describedby="firstName"
                               value={this.state.first_name} onChange={(e) => this.updateField(e, 'first_name')}
                               placeholder="First Name"/>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" className="form-control" value={this.state.last_name}
                               onChange={(e) => this.updateField(e, 'last_name')} placeholder="Last Name"/>
                    </div>
                    <div className="form-group col-md-12 mb-1">
                        <label htmlFor="email">Email</label>
                        <input type="text" className="form-control" value={this.state.email} readOnly/>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" value={this.state.password}
                               onChange={(e) => this.updateField(e, 'password')}/>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="passwordConf">Password Confirmation</label>
                        <input type="password" className="form-control" value={this.state.password_confirmation}
                               required
                               onChange={(e) => this.updateField(e, 'password_confirmation')}
                        />
                        <div className="invalid-feedback" ref={this.passConRef}>
                            Passwords do not match.
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="submit"
                                disabled={this.state.disabled}
                                onClick={this.update.bind(this)} className="btn btn-primary btn-block mt-2">Update
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default User;