import React, {useEffect, useState} from 'react';
import axios from "axios";
import {httpRequest} from '../services/httpRequest';
import {PersonEditOptions} from "./PersonInterface";
import {calculateAge} from "../services/helpers";
import {toast} from "react-toastify";

export default function Person(props: any) {
    const [person, setPerson] = useState<any>({locale: [], reference: []})

    const update = () => {
        axios.post(`/api/v1/person/${props.personId}/edit`, {person: person}, httpRequest.getHeaders())
            .then(() => {
                toast.info(`${person.name} has been updated!`);
            })
            .catch(error => httpRequest.errorHandler(error, props.history));
    }

    useEffect(() => {
        axios.get(`/api/v1/person/${props.personId}`, httpRequest.getHeaders())
            .then(response => {
                setPerson(response.data);
            })
            .catch(error => httpRequest.errorHandler(error, props.history));
    }, [])

    const updateField = (e, field) => {
        setPerson({
            ...person,
            [field]: e.target.value
        })
    }

    const updateCheckboxInField = (e: any, field: any, id?: any) => {
        const changeSet = [...person[field]];

        if (e.target.checked) {
            changeSet.push(id || e.target.id);
        } else {
            changeSet.splice(changeSet.indexOf(id || e.target.id), 1)
        }

        setPerson({
            ...person,
            [field]: changeSet
        })
    }


    return (<div className="col-md-4 col-sm-12">
        <div className="card">
            <div className="card-header bg-primary text-white">
                <h5>{person.name ? person.name : person.first_name} {person.dob ? `- ${calculateAge(person.dob)}` : null} {person.pronouns ? `(${person.pronouns})` : null}</h5>
            </div>
            <div className="card-body">
                <form className="row g-3">
                    {PersonEditOptions.map((event, idx) => {
                        if (event.type === "radio") {
                            return (<div className={event.class} key={idx}>
                                <label htmlFor={event.field}>{event.display}</label>
                                <select className="form-control"
                                        onChange={(e) => updateField(e, event.field)}
                                        value={person[event.field.toString()]}
                                        defaultValue={event.display}>
                                    {event.options.map((option, i) => {
                                        return (<option key={i}
                                                        value={option.id.toString()}>{option.display}</option>)
                                    })}
                                </select>
                            </div>)
                        }
                        if (event.type === 'textarea') {
                            return (<div className={event.class} key={idx}>
                                <label className="form-label">{event.display}</label>
                                <textarea defaultValue={person[event.field]}
                                          onChange={(e) => updateField(e, event.field)}
                                          className="form-control" aria-label={event.display}/>
                            </div>)
                        }
                        if (event.type === 'checkbox') {
                            return (<div className={event.class} key={idx}>
                                <label htmlFor={event.field}>{event.display}</label>
                                {event.options.map((option, i) => {
                                    return (<div className={`form-check ${event.class}`} key={i}>
                                        <input className="form-check-input"
                                               onChange={(e) => updateCheckboxInField(e, event.field, option.id)}
                                               name={event.field}
                                               checked={person[event.field].indexOf(option.id) > -1}
                                               type="checkbox"/>
                                        <label className="form-check-label" htmlFor={event.field}>
                                            {option.display}
                                        </label>
                                    </div>)
                                })}
                            </div>)
                        }
                        if (event.type === 'checkbox-single') {
                            return (<div className={event.class} key={idx}>
                                <label htmlFor={event.field}>{event.display}</label>
                                {event.options.map((option, i) => {
                                    return (<div className={`form-check ${event.class}`} key={i}>
                                        <input className="form-check-input"
                                               onChange={(z) => updateField({target: {value: z.target.checked}}, event.field)}
                                               name={event.field}
                                               checked={person[event.field] === option.id}
                                               type="checkbox" id={option.id.toString()}/>
                                        <label className="form-check-label" htmlFor={event.field}>
                                            {option.display}
                                        </label>
                                    </div>)
                                })}
                            </div>)
                        }
                        return (<div className={event.class} key={idx}>
                            <label className="form-label">{event.display}</label>
                            <input defaultValue={person[event.field]}
                                   onChange={(e) => updateField(e, event.field)}
                                   type={event.type}
                                   className="form-control" aria-label={event.display}/>
                        </div>)
                    })}
                </form>
            </div>
            <div className="card-footer">
                <button onClick={update}
                        className="btn btn-outline-primary btn-block" type="button">Update Client
                </button>
            </div>
        </div>
    </div>)
}