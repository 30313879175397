import React, {useEffect, useState} from 'react';
import axios from "axios";
import {httpRequest} from "../services/httpRequest";
import {formatDateNoOffset} from "../services/helpers";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

export default function CheckedIn(props) {
    const [checkedInList, setCheckedInList] = useState([]);
    useEffect(() => {
        axios.get(`/api/v1/checked-in/list`,
            httpRequest.getHeaders())
            .then(response => {
                setCheckedInList(response.data.checked_in);
            })
            .catch(error => httpRequest.errorHandler(error, props.history));
    }, []);

    const itemLookup = (item) => {
        return {
            "idk": "I don't know",
            'false': 'No',
            'true': 'True',
            'other': 'Other',
        }[item];
    }

    const createNew = (person: any, id: any) => {
        axios.post(`/api/v1/create-person?checked_in=${id}`, {
            person: {name: person.name, first_name: person.first_name, last_name: person.last_name}
        }, httpRequest.getHeaders(),).then((response) => {
            props.history.push(`/check-in/${response.data.id}`);
        }).catch(error => httpRequest.errorHandler(error, props.history))
    }

    const checkOut = (person, idx) => {
        axios.post(`/api/v1/public/check-out`, {id: person.id})
            .then((result) => {
                let updatedList = [...checkedInList];
                updatedList.splice(idx, 1);
                setCheckedInList(updatedList)
                toast.success(`You have checked out, ${person.name}`);
            }).catch((error) => toast.error(error))
    }

    return (
        <div className="col-12 mt-2">
            <div className={'card mt-4'}>
                <div className={'card-header'}>
                    Currently Checked-in
                </div>
                <div className={'card-body'}>
                    {checkedInList.length === 0 && (<div>
                        <h6>No one is currently checked in!</h6>
                    </div>)}
                    {checkedInList.length > 0 && (
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Children brought in</th>
                                <th scope="col">Fever question</th>
                                <th scope="col">Covid question</th>
                                <th scope="col">Visiting</th>
                                <th scope="col">Checked in @</th>
                                <th scope="col">Youth</th>
                                <th scope="col">Checkout</th>
                            </tr>
                            </thead>
                            <tbody>
                            {checkedInList.map((item, idx) => {
                                return (
                                    <tr key={item.id}>
                                        <th>{item.name ? item.name : item.first_name}</th>
                                        <td>{item.children}</td>
                                        <td>{itemLookup(item.fever)}</td>
                                        <td>{itemLookup(item.covid)} {item.other_data}</td>
                                        <td>{item.visiting}</td>
                                        <td>{formatDateNoOffset(item.created_at)}</td>
                                        <td>{item.person_id ?
                                            <Link to={"/check-in/" + item.person_id}
                                                  props={{personId: item.person_id}}>{item.name ? item.name : item.first_name}</Link> :
                                            <button onClick={() => createNew(item, item.id)}
                                                    className={'btn btn-primary btn-sm btn-block'}>Create
                                                new</button>}</td>
                                        <td>
                                            <button className={'btn btn-primary btn-sm btn-block'} onClick={() => {
                                                checkOut(item, idx)
                                            }}>Check out
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    )
}