import {differenceInYears, format, formatRelative, parse, parseISO} from 'date-fns'

export const formatDate = (date: any) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const parsedResult = parseISO(date);
    const result = new Date(parsedResult.getTime() - (timezoneOffset * 60000))

    return formatRelative(result, new Date());
}
export const formatDateNoOffset = (date: any) => {
    const result = parseISO(date);

    return formatRelative(result, new Date());
}

export const calculateAge = (date) => {
    const result = parseISO(date);

    return differenceInYears(new Date(), result);
}

export const dateSort = (a: any, b: any) => {
    return (new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
}

export const updateField = (e, key, state) => {
    state.setState({
        [key]: e.target.value
    });
}

export const updateObjectInField = (e, key, state, type, override?) => {
    state.setState({
        [type]: {
            ...state.state[type],
            [key]: override? override : e.target.value
        }
    })
}

export const updateCheckboxInField = (e, key, state, type) => {
    const changeSet = state.state[type][key];

    if (e.target.checked) {
        changeSet.push(e.target.id);
    } else {
        changeSet.splice(changeSet.indexOf(e.target.id), 1)
    }

    state.setState({
        [type]: {
            ...state.state[type],
            [key]: changeSet,
        }
    })
}