import {Card, Checkbox, Col, Input, Radio, Row, Space, Typography} from "antd";
import React from "react";

export const SurveyOptions = ({item, responseData, updateQuestion, updateCheckBox, updateGridQuestion}) => {
    return(
        <Card title={item.description}>
            {item.type === 'input' &&
                <Input
                    disabled={responseData.completed || responseData.expired}
                    value={item.value}
                    onChange={(e) => updateQuestion(item.order, e.target.value)}
                    placeholder={'Short answer text'}/>
            }
            {item.type === 'select' &&
                <Radio.Group
                    disabled={responseData.completed || responseData.expired}
                    onChange={(e) => updateQuestion(item.order, e.target.value)}
                    value={item.value}>
                    <Space direction="vertical">
                        {item.options.map((opt: any) =>
                            (<Radio key={opt.order}
                                    value={opt.order}>{opt.description}</Radio>
                            )
                        )}
                    </Space>
                </Radio.Group>
            }
            {(item.type === 'checkbox') &&
                (<Row>
                    {item.options?.map((option: any) => (
                        <Col xs={24} key={option.order} className={'mb-1'}>
                            <Row gutter={16}>
                                <Col xs={1}>
                                    <Checkbox
                                        disabled={responseData.completed || responseData.expired}
                                        checked={(item.value || []).indexOf(option.order) > -1}
                                        onChange={(e) => updateCheckBox(item.order, option.order, e.target.checked)}/>
                                </Col>
                                <Col xs={19}>
                                    {option.description}
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>)
            }
            {item.type === 'grid' &&
                <Row gutter={8}>
                    <Col xs={24}>
                        <Row className={'mb-1'}>
                            <Col xs={0} sm={16}></Col>
                            <Col xs={0} sm={8}>
                                <Row className={'text-center'}>
                                    {item.columns?.map((column: any) => (
                                        <Col
                                            xs={Math.floor(24 / item.columns.length)}
                                            key={column.order}>
                                            <Typography.Text
                                                strong>{column.description}</Typography.Text>
                                        </Col>
                                    ))
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        {item.rows?.map((row: any) => (
                            <Row key={row.order} gutter={16}
                                 className={'mb-2'}>
                                <Col xs={24} sm={16}>
                                    {row.description}
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Radio.Group
                                        style={{width: '100%'}}
                                        onChange={(e) => updateGridQuestion(item.order, row.order, e.target.value)}
                                        value={row.value}>
                                        <Row className={'text-center'}>
                                            <Col xs={24} sm={0}>
                                                <Row className={'text-center'}>
                                                    {item.columns?.map((column: any) => (
                                                        <Col
                                                            xs={Math.floor(24 / item.columns.length)}
                                                            key={column.order}>
                                                            <Typography.Text
                                                                strong>{column.description}</Typography.Text>
                                                        </Col>
                                                    ))
                                                    }
                                                </Row>
                                            </Col>
                                            {item.columns?.map((column: any) => (
                                                <Col
                                                    xs={Math.floor(24 / item.columns.length)}
                                                    key={`${row.order}-${column.order}`}>
                                                    <Radio
                                                        disabled={responseData.completed || responseData.expired}
                                                        value={column.order}/>
                                                </Col>
                                            ))
                                            }
                                        </Row>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        ))
                        }
                    </Col>
                </Row>
            }
        </Card>
    )
}