import React, {Component} from 'react';
import axios from "axios";
import {httpRequest} from "../services/httpRequest";
import Logo from '../../../assets/images/logo-green.png';

class SignIn extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            disabled: false,
        }
    }

    login() {
        if (!this.state.email || !this.state.password) return;
        this.setState({disabled: true});
        axios.post(`/api/v1/sign_in`, {
            "user": {
                "email": this.state.email,
                "password": this.state.password
            }
        }).then(response => {
            sessionStorage.setItem('authToken', response.headers.authorization);
            sessionStorage.setItem('user', JSON.stringify(response.data));
            this.props.history.push("/");
        }).catch(error => {
            this.setState({disabled: false});
            httpRequest.errorHandler(error, this.props.history)
        });
    }

    updateField(e, state) {
        this.setState({[state]: e.target.value});
    }

    render() {
        return (
            <div className="col-12 mt-2">
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group row justify-content-md-center">
                        <div className="card col-md-6 col-sm-12">
                            <img src={Logo} className="card-img-top" alt="Logo"/>
                            <div className="card-body">
                                <label className="form-label" htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" className="form-control" aria-describedby="emailHelp"
                                       value={this.state.email} onChange={(e) => this.updateField(e, 'email')}
                                       placeholder="Enter email"/>
                                <label className="form-label" htmlFor="exampleInputPassword1">Password</label>
                                <input type="password" className="form-control" value={this.state.password}
                                       onChange={(e) => this.updateField(e, 'password')} placeholder="Password"/>
                                <div className="d-flex justify-content-center">
                                    <button type="submit"
                                            disabled={this.state.disabled}
                                            onClick={this.login.bind(this)} className="btn btn-primary btn-block mt-2">
                                        Login
                                        <span className="spinner-border spinner-border-sm" role="status"
                                              aria-hidden="true"
                                              hidden={!this.state.disabled}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default SignIn;