import React, {Component} from 'react';
import axios from 'axios';
import PersonList from "../people/PersonList";
import {httpRequest} from "../services/httpRequest";
import CreatePerson from "../people/CreatePerson";
import {Link} from "react-router-dom";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            term: '',
            autoCompleteResults: [],
        };
    }

    getAutoCompleteResults(e) {
        this.setState({
            term: e.target.value
        }, () => {
            if (!this.state.term)
                return this.setState({autoCompleteResults: []});
            axios.get(`/api/v1?search=${this.state.term}`, httpRequest.getHeaders())
                .then(response => {
                    this.setState({autoCompleteResults: response.data.people})
                })
                .catch(error => httpRequest.errorHandler(error, this.props.history));
        });
    }

    render() {
        return (
            <div className="col-12 mt-2">
                <div className="card">
                    <h5 className="card-header">Youth Search</h5>
                    <div className="card-body">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Youth's Name</span>
                            </div>
                            <input ref={(input) => {
                                this.searchBar = input
                            }}
                                   onChange={this.getAutoCompleteResults.bind(this)}
                                   placeholder='Search...' aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                   value={this.state.term} type="text" className="form-control"/>
                        </div>
                    </div>

                    <PersonList people={this.state.autoCompleteResults}/>

                    <h5 className="card-header">Create new youth record</h5>
                    <div className="card-body">
                        <CreatePerson history={this.props.history}/>
                    </div>
                    <h5 className="card-header">Brief Service</h5>
                    <div className="card-body">
                        <Link className={'no-hover'} to="/brief-service/">
                            <button className="btn btn-block btn-outline-primary">Create Brief Service Item</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home
