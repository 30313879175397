'use strict';

export class NoteClass {
    note: string;
    private: boolean;

    constructor() {
        this.note = "";
        this.private = false;
    }
}