import React, {Component} from 'react';
import {Link} from "react-router-dom";

class PersonList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="card-body pt-0">
                <ul className="list-group" hidden={!this.props.people.length}>
                    {this.props.people.map((person) =>
                        <Link to={"/check-in/" + person.id} props={{personId: person.id}} key={person.id}>
                            <div className="list-group-item d-flex align-items-end">
                                <div className="col-7">
                                    {person.name ? person.name : person.first_name}
                                    {person.pronouns ? ` (${person.pronouns})` : null}
                                </div>
                                <div className="col-5">
                                    <div className="row align-items-end">
                                        <div className="col-md-6 d-none d-md-block">
                                        <span hidden={!person.first_name} className="badge badge-primary badge-pill text-white">
                                    {person.first_name}
                                            {person.last_name ? ` ${person.last_name}` : null}</span>
                                        </div>
                                        <div className="col-md-6 d-none d-md-block">
                                        <span hidden={!person.dob}
                                              className="badge badge-secondary badge-pill">DOB: {person.dob}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )}
                </ul>
                <p hidden={this.props.people.length}>No results found.</p>
            </div>
        )
    }
}

export default PersonList;