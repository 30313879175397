import React, {Component} from 'react';
import axios from "axios";
import {httpRequest} from "../services/httpRequest";

class CreatePerson extends Component {
    constructor(props) {
        super(props);

        this.state = {
            person: {
                name: '', dob: ''
            },
        };
    }

    updateField(e, type) {
        this.setState({
            person: {
                ...this.state.person, [type]: e.target.value,
            }
        })
    }

    save() {
        axios.post(`/api/v1/create-person`, {
            person: this.state.person
        }, httpRequest.getHeaders(),).then((response) => {
            this.props.history.push(`/check-in/${response.data.id}`);
        }).catch(error => httpRequest.errorHandler(error, this.props.history))
    }

    render() {
        return (<div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text d-none d-md-block" id="inputGroup-sizing-sm">Youth's Name</span>
                </div>
                <input value={this.state.person.name} onChange={(e) => this.updateField(e, 'name')} type='text'
                       className="form-control" placeholder="Name" aria-label="Name of client"/>
                <div className="input-group-prepend d-none d-md-block">
                    <span className="input-group-text" id="inputGroup-sizing-sm">Youth's DOB</span>
                </div>
                <input value={this.state.person.dob} onChange={(e) => this.updateField(e, 'dob')} type='date'
                       placeholder="DOB of client" className="form-control" aria-label="Client DOB"/>
                <div className="input-group-append">
                    <button onClick={this.save.bind(this)} className="btn btn-outline-primary"
                            type="button">Save
                    </button>
                </div>
            </div>)
    }
}

export default CreatePerson;