import React, {useEffect, useState} from 'react';
import axios from "axios";
import {httpRequest} from "../services/httpRequest";

export default function FindDuplicates(props: any) {
    const [duplicates, setDuplicates] = useState([]);
    const [find, setFind] = useState(false);
    const [hasRun, setHasRun] = useState(false);

    useEffect(() => {
        if (find) {
            axios.get(`/api/v1/duplicates/show`,
                httpRequest.getHeaders())
                .then(response => {
                    setDuplicates(response.data.duplicates);
                })
                .catch(error => httpRequest.errorHandler(error, props.history))
                .finally(() => {
                    setFind(false);
                    setHasRun(true);
                });
        }
    }, [find]);

    return (
        <div className="col-12 mt-2">
            <div className={'card mt-4'}>
                <div className={'card-header'}>
                    Duplicates
                </div>
                <div className={'card-body'}>
                    {!find && !duplicates.length && !hasRun && (
                        <button onClick={() => setFind(true)} className={'btn btn-primary btn-block'}>Find Duplicates</button>
                    )}
                    {find && (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="visually-hidden"></span>
                            </div>
                        </div>
                    )}
                    {duplicates.length === 0 && hasRun && (
                        <div>
                            <h6>No results!</h6>
                        </div>)}
                    {duplicates.length > 0 && (
                        <>
                            {duplicates.map((item: any, idx: number) => {
                                return (
                                    <div className={'card mb-2'} key={idx}>
                                        <div
                                            className={`card-header text-white ${idx % 2 == 0 ? 'bg-primary' : 'bg-secondary'}`}>
                                            Match Score of {((1 - item[2]) * 100).toFixed(0)}%
                                        </div>
                                        <div className={'card-body'}>
                                            <div className={'row mb-2'}>
                                                {item.filter((i: any) => i.id).map((i: any, idx2: number) => {
                                                    return (
                                                        <div className={'col-6'} key={i.id}>
                                                            <div
                                                                className={`card`}>
                                                                <div className={'card-header'}>
                                                                    {i.name}
                                                                </div>
                                                                <div className={'card-body'}>
                                                                    <p>First name: {i.first_name}</p>
                                                                    <p>Last name: {i.last_name}</p>
                                                                    <p>Email: {i.email}</p>
                                                                    <p>DOB: {i.dob}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}