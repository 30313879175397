import React, {useState} from "react";


export default function CreateNote(props) {
    const [checked, setChecked] = useState(false);

    function updateField(e) {
        props.setNote({
            ...props.note,
            [e.target.name]: e.target.value
        })
    }

    function updateNoteChecked(e) {
        setChecked(!checked);
        props.setNote({
            ...props.note,
            private: !checked,
        })
    }

    return (
        <div className={'row'}>
            <form className={'col-12'}>
                <div className="input-group">
                    <textarea value={props.note.note}
                              onChange={updateField}
                              name={'note'}
                              rows={4}
                              className="form-control" aria-label="With textarea"/>
                    <div className="input-group-append">
                        <div className="input-group-text form-check pl-4">
                            <input value={props.note.private}
                                   onChange={updateNoteChecked}
                                   id="privateCheckbox" className="form-check-input"
                                   type="checkbox" aria-label="Checkbox for private"/>
                            <label className="form-check-label" htmlFor="privateCheckbox">Private?</label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}