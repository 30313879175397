import React, {useEffect, useState} from 'react';
import axios from "axios";
import {httpRequest} from "../services/httpRequest";
import {formatDateNoOffset} from "../services/helpers";
import {Link} from "react-router-dom";
import {startOfDay, endOfDay, formatISO, format, differenceInHours} from 'date-fns';

export default function CheckedInStatus(props) {
    const [checkedInList, setCheckedInList] = useState([]);
    const [fromDate, setFromDate] = useState(startOfDay(new Date()));
    const [toDate, setToDate] = useState(endOfDay(new Date()));
    const [shouldUpdate, setShouldUpdate] = useState(true);

    useEffect(() => {
        if (shouldUpdate) {
            axios.get(`/api/v1/checked-in/search?from=${formatISO(fromDate)}&to=${formatISO(toDate)}`,
                httpRequest.getHeaders())
                .then(response => {
                    setCheckedInList(response.data.checked_in);
                })
                .catch(error => httpRequest.errorHandler(error, props.history))
                .finally(() => setShouldUpdate(false));
        }
    }, [shouldUpdate]);

    const itemLookup = (item) => {
        return {
            "idk": "I don't know",
            'false': 'No',
            'true': 'True',
            'other': 'Other',
        }[item];
    }

    const createNew = (person: any, id: any) => {
        axios.post(`/api/v1/create-person?checked_in=${id}`, {
            person: {name: person.name, first_name: person.first_name, last_name: person.last_name}
        }, httpRequest.getHeaders(),).then((response) => {
            props.history.push(`/check-in/${response.data.id}`);
        }).catch(error => httpRequest.errorHandler(error, props.history))
    }

    const updateDate = (date: string, fnType: any, callback: any) => {
        const updatedDate = new Date(date)
        const userTimezoneOffset = updatedDate.getTimezoneOffset() * 60000;
        callback(fnType(new Date(updatedDate.getTime() + userTimezoneOffset)));
    }

    return (
        <div className="col-12 mt-2">
            <div className={'card mt-4'}>
                <div className={'card-header'}>
                    Checked-in Status
                </div>
                <div className={'card-body'}>
                    <div className={'row mb-2'}>
                        <div className={'col-3'}>
                            <label className="form-label">From Date</label>
                            <input value={format(fromDate, 'yyyy-MM-dd')}
                                   onChange={(e) => updateDate(e.target.value, startOfDay, setFromDate)}
                                   type={'date'}
                                   className="form-control" aria-label={'From Date'}/>
                        </div>
                        <div className={'col-3'}>
                            <label className="form-label">To Date</label>
                            <input value={format(toDate, 'yyyy-MM-dd')}
                                   onChange={(e) => updateDate(e.target.value, endOfDay, setToDate)}
                                   type={'date'}
                                   className="form-control" aria-label={'From Date'}/>
                        </div>
                        <div className={'col-6'}>
                            <button className={'btn btn-primary btn-sm btn-block'}
                                    onClick={() => setShouldUpdate(true)}>Search
                            </button>
                        </div>
                    </div>

                    {checkedInList.length === 0 && (<div>
                        <h6>No results!</h6>
                    </div>)}
                    {checkedInList.length > 0 && (
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Children brought in</th>
                                <th scope="col">Fever question</th>
                                <th scope="col">Covid question</th>
                                <th scope="col">Visiting</th>
                                <th scope="col">Checked in @</th>
                                <th scope="col">Checked out @</th>
                                <th scope="col">Duration @ Lounge</th>
                                <th scope="col">Youth</th>
                            </tr>
                            </thead>
                            <tbody>
                            {checkedInList.map((item, idx) => {
                                return (
                                    <tr key={item.id}>
                                        <th>{item.name ? item.name : item.first_name}</th>
                                        <td>{item.children}</td>
                                        <td>{itemLookup(item.fever)}</td>
                                        <td>{itemLookup(item.covid)} {item.other_data}</td>
                                        <td>{item.visiting}</td>
                                        <td>{formatDateNoOffset(item.created_at)}</td>
                                        <td>{formatDateNoOffset(item.updated_at)}</td>
                                        <td>~{differenceInHours(new Date(item.updated_at), new Date(item.created_at), {roundingMethod: 'ceil'})} hour(s)</td>
                                        <td>{item.person_id ?
                                            <Link to={"/check-in/" + item.person_id}
                                                  // @ts-ignore
                                                  props={{personId: item.person_id}}>{item.name ? item.name : item.first_name}</Link> :
                                            <button onClick={() => createNew(item, item.id)}
                                                    className={'btn btn-primary btn-sm btn-block'}>Create
                                                new</button>}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    )
}