import React, {useEffect, useState} from 'react';
// @ts-ignore
import Logo from '../../../assets/images/logo-green-main.png';

import axios from "axios";
import PersonListPublic from "../people/PersonListPublic";
import PersonCheckin from "../people/PersonCheckin";
import {toast} from "react-toastify";

export default function SignIntoLyte(props) {
    const [searchBar, setSearchBar] = useState<any>()
    const [person, setPerson] = useState<any>(null)
    const [searchTerm, setSearchTerm] = useState('');
    const [checkoutSearch, setCheckoutSearch] = useState('');
    const [autoCompleteResults, setAutoCompeleteResults] = useState([]);
    const [action, setAction] = useState(null);
    const [userList, setUserList] = useState([])

    useEffect(() => {
        axios.get(`/api/v1/public/user-list`)
            .then((result) => {
                setUserList(result.data);
            }).catch((error) => toast.error(error))
    }, []);

    useEffect(() => {
        if (!searchTerm) return;
        axios.get(`/api/v1/public?search=${searchTerm}`)
            .then((result) => {
                setAutoCompeleteResults(result.data.people);
            }).catch((error) => toast.error(error))
    }, [searchTerm]);

    useEffect(() => {
        if (!checkoutSearch) return;
        axios.get(`/api/v1/public/check-out?search=${checkoutSearch}`)
            .then((result) => {
                setAutoCompeleteResults(result.data.people);
            }).catch((error) => toast.error(error))
    }, [checkoutSearch]);

    const setPersonUpdate = (person: any) => {
        if (checkoutSearch) {
            axios.post(`/api/v1/public/check-out`, {id: person.id})
                .then((result) => {
                    toast.success(`You have been checked out, ${person.name}`);
                    setPerson(null);
                    setAction(null);
                }).catch((error) => toast.error(error))
            setCheckoutSearch('');
        } else {
            setPerson(person);
            setSearchTerm('');
        }
        setAutoCompeleteResults([]);
    }

    const performCheckIn = (data: any) => {
        const _data = {...data, visiting: data.other_visiting ? data.other_visiting : data.visiting,};
        delete _data.other_visiting;

        axios.post(`/api/v1/public/check-in`, {check_in: _data})
            .then((result) => {
                toast.success(`You have been checked in, ${data.name}`);
                setPerson(null);
                setAction(null);
            }).catch((error) => toast.error(error))
    }

    return (
        <div className="col-12 mt-2">
            <img src={Logo} alt={'Lyte logo'} style={{width: 150, margin: 20}} />
            <div className="card">
                {!action && (
                    <div className={'card-body large-modular'}>
                        <button className={'btn btn-primary btn-block'} onClick={() => setAction('in')}>
                            Sign in
                        </button>
                        <button className={'btn btn-secondary btn-block'} onClick={() => setAction('out')}>
                            Sign out
                        </button>
                    </div>
                )}
                {!person && action === 'in' && (
                    <div className="card-body">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Name</span>
                            </div>
                            <input ref={(input) => {
                                setSearchBar(input)
                            }}
                                   onChange={(e) => setSearchTerm(e.target.value)}
                                   placeholder='Search...' aria-label="Small"
                                   aria-describedby="inputGroup-sizing-sm"
                                   value={searchTerm}
                                   type="text" className="form-control"/>
                        </div>
                        <PersonListPublic setPerson={setPersonUpdate} term={searchTerm}
                                          people={autoCompleteResults}/>
                    </div>
                )}
                {person && (
                    <div className="card-body">
                        <div className={'card-header'}>Signing in</div>
                        <PersonCheckin userList={userList} person={person} performCheckIn={performCheckIn}/>
                    </div>
                )}
                {action === 'out' && (
                    <div className="card-body">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">Name</span>
                            </div>
                            <input ref={(input) => {
                                setSearchBar(input)
                            }}
                                   onChange={(e) => setCheckoutSearch(e.target.value)}
                                   placeholder='Search...' aria-label="Small"
                                   aria-describedby="inputGroup-sizing-sm"
                                   value={checkoutSearch}
                                   type="text" className="form-control"/>
                        </div>
                        <PersonListPublic checkoutPerson={true} setPerson={setPersonUpdate}
                                          people={autoCompleteResults}/>
                    </div>
                )}
            </div>
        </div>
    )
}