import React, {Component} from 'react';

class Resource extends Component {
    getSubResourceList() {
        if (this.props.data.sub_resources.length && this.props.contact.services.indexOf(this.props.data.id.toString()) > -1) {
            return (this.props.data.sub_resources.map((item) => {
                return (
                    <div className="form-check" key={item.id}>
                        <input className="form-check-input"
                               onChange={(e) => this.props.handleServiceChange(e, 'services')}
                               checked={this.props.contact.services.indexOf(item.id.toString()) > -1}
                               name={'services'} type="checkBox" id={item.id}/><label
                        className="form-check-label"
                        htmlFor="services">{item.name}</label>
                    </div>
                );
            }));
        } else {
            return '';
        }
    }

    render() {
        return (
            <div className={'col-6'}>
                <div className="form-check">
                    <input className="form-check-input" onChange={(e) => this.props.handleServiceChange(e, 'services')}
                           checked={this.props.contact.services.indexOf(this.props.data.id.toString()) > -1}
                           name={'services'} type="checkBox" id={this.props.data.id}/><label
                    className="form-check-label" htmlFor="services">{this.props.data.name || 'Unknown Service'}</label>
                    {this.getSubResourceList()}
                </div>
            </div>
        )
    }
}

export default Resource;