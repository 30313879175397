import React, {useState} from "react";

export default function PersonCheckin(props: any) {
    const [checkIn, setCheckIn] = useState({
        children: '0',
        visiting: '',
        fever: '',
        covid: '',
        other_data: '',
        person_id: props.person.id || null,
        first_name: props.person.first_name || '',
        last_name: props.person.last_name || '',
        name: props.person.name || '',
        other_visiting: ''
    })
    return (
        <>
            {props.person && (
                <form>
                    <div className={'row mb-4 mt-4'}>
                        <div className={'col-sm-4'}>
                            <label className="form-label">Preferred name</label>
                            <input className={'form-control'}
                                   name={'name'}
                                   onChange={(e) => {
                                       setCheckIn({...checkIn, name: e.target.value})
                                   }}
                                   disabled={Object.keys(props.person).length > 0}
                                   defaultValue={props.person.name}/>
                        </div>

                        <div className={'col-sm-4'}>
                            <label className="form-label">First name</label>
                            <input className={'form-control'}
                                   name={'first_name'}
                                   onChange={(e) => {
                                       setCheckIn({...checkIn, first_name: e.target.value})
                                   }}
                                   disabled={Object.keys(props.person).length > 0}
                                   defaultValue={props.person.first_name}/>
                        </div>

                        <div className={'col-sm-4'}>
                            <label className="form-label">Last name</label>
                            <input className={'form-control'}
                                   name={'last_name'}
                                   onChange={(e) => {
                                       setCheckIn({...checkIn, last_name: e.target.value})
                                   }}
                                   disabled={Object.keys(props.person).length > 0}
                                   defaultValue={props.person.last_name}/>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-sm-4 mb-4'}>
                            <label className="form-label">How many children are you bringing in today?</label>
                            <input className={'form-control'}
                                   onChange={(e) => setCheckIn({...checkIn, children: e.target.value})}
                                   name={'children'}/>
                        </div>
                        <div className={'col-sm-8 mb-4'}>
                            <label htmlFor='visiting'>Who are you here to see?</label>
                            <select className="form-control"
                                    onChange={(e) => setCheckIn({...checkIn, visiting: e.target.value})}
                                    defaultValue={'select'}>
                                <option value={'select'}>
                                    Select one
                                </option>
                                {props.userList.map((option, i) => {
                                    return (<option key={i}
                                                    value={option}>{option}</option>)
                                })}
                                <option key={'other'} value={'other'}>Other</option>
                            </select>
                            {checkIn.visiting === 'other' && (
                                <input className={'form-control'}
                                       onChange={(e) => setCheckIn({...checkIn, other_visiting: e.target.value})}
                                       name={'other_visiting'}/>
                            )}
                        </div>
                        <div className={'col-sm-6 mb-4'}>
                            <label htmlFor='fever'>Do you have a fever?</label>
                            <select className="form-control"
                                    onChange={(e) => setCheckIn({...checkIn, fever: e.target.value})}
                                    defaultValue={'select'}>
                                <option value={'select'}>
                                    Select one
                                </option>
                                {[{id: false, display: 'No'}, {id: true, display: 'Yes'}, {
                                    id: 'idk',
                                    display: "Don't know"
                                }].map((option, i) => {
                                    return (<option key={i}
                                                    value={option.id.toString()}>{option.display}</option>)
                                })}
                            </select>
                        </div>
                        <div className={'col-sm-6 mb-4'}>
                            <label htmlFor='covid'>Have you been exposed to Covid in the last 14 days</label>
                            <select className="form-control"
                                    onChange={(e) => setCheckIn({...checkIn, covid: e.target.value, other_data: ''})}
                                    defaultValue={'select'}>
                                <option value={'select'}>
                                    Select one
                                </option>
                                {[{id: false, display: 'No'}, {id: true, display: 'Yes'}, {
                                    id: 'idk',
                                    display: "Don't know"
                                }, {id: 'other', display: 'Other'}].map((option, i) => {
                                    return (<option key={i}
                                                    value={option.id.toString()}>{option.display}</option>)
                                })}
                            </select>
                            {checkIn.covid === 'other' && (
                                <input className={'form-control'}
                                       onChange={(e) => setCheckIn({...checkIn, other_data: e.target.value})}
                                       name={'other_data'}/>
                            )}
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className="col-12 mb-2">
                            <button onClick={() => props.performCheckIn(checkIn)}
                                    disabled={!checkIn.covid || !checkIn.fever || !checkIn.visiting}
                                    className="btn btn-info btn-lg btn-block"
                                    type="button">Sign in
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </>
    )
}