import {Button, Card, Checkbox, Col, Descriptions, Drawer, List, Row, Tag, Typography} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {httpRequest} from "../services/httpRequest";
import dayjs from "dayjs";
import {TakeSurvey} from "./TakeSurvey";
import {toast} from "react-toastify";

export const PersonSurveys = ({personId, history}) => {
    const [surveys, setSurveys] = useState([]);
    const [surveyId, setSurveyId] = useState(null);
    const [optedOut, setOptedOut] = useState(false);

    useEffect(() => {
        if (personId) {
            axios.get(`/api/v1/person/${personId}/surveys`,
                httpRequest.getHeaders())
                .then(response => {
                    setSurveys(response.data.responses);
                    setOptedOut(response.data.optedOut);
                })
                .catch(error => httpRequest.errorHandler(error, history));
        }
    }, [personId]);

    const updateOptOut = (checked: any) => {
        setOptedOut(checked);

        axios.post(`/api/v1/person/${personId}/edit`, {
            person: {opt_out_comm: checked}
        }, httpRequest.getHeaders(),).then((response) => {
            toast.success(`Successfully opted ${checked ? 'out of ' : 'in to '} communications.`)
        }).catch(error => httpRequest.errorHandler(error, history))
    }

    return (
        <Row>
            <Col xs={24}>
                <h5 className="card-header">Survey Responses</h5>
                <div className="card-body" style={{width: '100%'}}>
                    <Checkbox
                        checked={optedOut}
                        onChange={(e) => updateOptOut(e.target.checked)}>Opt Out Of Responses</Checkbox>
                    <List
                        dataSource={surveys}
                        renderItem={(item: any) => (
                            <List.Item>
                                <Descriptions size={'small'} bordered items={[
                                    {
                                        key: 4,
                                        label: 'Survey',
                                        span: 2,
                                        children: item.name
                                    },
                                    {
                                        key: 0,
                                        label: 'Sent',
                                        span: 1,
                                        children: dayjs(item.createdAt).format('MMM DD, YYYY')
                                    },
                                    {
                                        key: 1,
                                        label: 'Completed',
                                        children: item.completed ? <Tag color={'green'}>Yes</Tag> : <Tag color={'red'}>No</Tag>
                                    },
                                    {
                                        key: 2,
                                        label: 'Started',
                                        children: item.started ? <Tag color={'green'}>Yes</Tag> : <Tag color={'red'}>No</Tag>
                                    },
                                    {
                                        key: 3,
                                        label: 'Expired',
                                        children: item.expired ? <Tag color={'red'}>Yes</Tag> : <Tag color={'green'}>No</Tag>
                                    },
                                ]}/>
                                {item.completed && <Button type={'primary'} onClick={() => setSurveyId(item.hash)} ghost>View Results</Button>}
                            </List.Item>
                        )}
                    />
                </div>
                <Drawer width={'50%'} open={surveyId} onClose={() => setSurveyId(null)}>
                    <TakeSurvey match={{params: {id: surveyId, isAdmin: true}}} />
                </Drawer>
            </Col>
        </Row>
    )
}