import {Card, Col, Divider, Progress, Row, Statistic, Typography} from "antd";
import React, {useEffect, useState} from "react";

export const SurveyResults = ({results}) => {
    const [surveysSent, setSurveysSent] = useState(0);
    const [surveysExpired, setSurveysExpired] = useState(0);
    const [surveysCompleted, setSurveysCompleted] = useState(0);
    const [questionSet, setQuestionSet] = useState([]);

    useEffect(() => {
        setSurveysSent(results.length);
        setSurveysExpired(results.filter((res: any) => res.expired).length);
        setSurveysCompleted(results.filter((res: any) => res.completed).length);
        setQuestionSet(results[0].data.map((q: any) => ({
            name: q.description,
            order: q.order,
            type: q.type
        })))
    }, [results]);

    const getValueForQuestion = (q: any) => {
        const dataNeeded = results.map((res: any) => res.data.find((ques: any) => ques.order === q.order));

        switch (q.type) {
            case 'input': {
                let resultSet = {};
                dataNeeded.forEach((res: any) => {
                    if (res?.value)
                        resultSet = {
                            ...resultSet,
                            [res.value]: (resultSet[res.value] || 0) + 1
                        }
                })

                return Object.entries(resultSet);
            }
            case 'grid': {
                let resultSet = {};
                let keySet = {};
                dataNeeded[0]?.columns?.forEach((opt: any) => {
                    keySet = {
                        ...keySet,
                        [opt.order]: opt.description
                    }
                });

                dataNeeded[0]?.rows?.forEach((row: any) => {
                    resultSet = {
                        ...resultSet,
                        [row.description]: Object.keys(keySet).map((key: any) => ({[keySet[key]]: 0}))
                    }
                });

                dataNeeded.forEach((response: any) => {
                    response?.rows?.forEach((res: any) => {
                        if (res?.value) {
                            resultSet = {
                                ...resultSet,
                                [res.description]: (resultSet[res.description]).map((result: any) => keySet[res.value] === Object.keys(result)[0] ? {
                                    [keySet[res.value]]: result[keySet[res.value]] + 1
                                } : result)
                            }
                        }
                    })
                });

                return Object.entries(resultSet);
            }
            case 'checkbox': {
                let resultSet = {};
                let keySet = {};

                dataNeeded[0]?.options?.forEach((opt: any) => {
                    resultSet = {
                        ...resultSet,
                        [opt.description]: 0
                    }
                    keySet = {
                        ...keySet,
                        [opt.order]: opt.description
                    }
                });

                dataNeeded.forEach((res: any) => {
                    if (res?.value) {
                        res.value.forEach((value: any) => {
                            resultSet = {
                                ...resultSet,
                                [keySet[value]]: (resultSet[keySet[value]] || 0) + 1
                            }
                        })
                    }
                });

                return Object.entries(resultSet);
            }
            default: {
                let resultSet = {};
                let keySet = {};

                dataNeeded[0]?.options.forEach((opt: any) => {
                    resultSet = {
                        ...resultSet,
                        [opt.description]: 0
                    }
                    keySet = {
                        ...keySet,
                        [opt.order]: opt.description
                    }
                });

                dataNeeded.forEach((res: any) => {
                    if (res?.value) {
                        resultSet = {
                            ...resultSet,
                            [keySet[res.value]]: (resultSet[keySet[res.value]] || 0) + 1
                        }
                    }
                });

                return Object.entries(resultSet);
            }
        }
    }

    return (
        <Row gutter={8}>
            <Col className={'mb-2'} xs={24} sm={8}>
                <Card>
                    <Statistic
                        title="Surveys Sent All Time"
                        value={surveysSent}
                        valueStyle={{color: '#3f8600'}}
                    />
                </Card>
            </Col>
            <Col className={'mb-2'} xs={24} sm={8}>
                <Card>
                    <Statistic
                        title="Surveys Completed All Time"
                        value={surveysCompleted}
                        valueStyle={{color: '#3f8600'}}
                    />
                </Card>
            </Col>
            <Col className={'mb-2'} xs={24} sm={8}>
                <Card>
                    <Statistic
                        title="Surveys Expired All Time"
                        value={surveysExpired}
                        valueStyle={{color: '#860000'}}
                    />
                </Card>
            </Col>
            <Divider/>
            {questionSet.map((q: any) => (
                <Col className={'mb-2'} xs={24} sm={12} key={q.order}>
                    <Card title={q.name}>
                        {getValueForQuestion(q).map((item: any, idx: any) => (
                            q.type === 'grid' ? <Row key={idx}>
                                    <Typography.Text strong className={'mb-1'}>{item[0]}</Typography.Text>
                                    {item[1].map((itz: any, idx: any) => (
                                        <Progress key={idx} percent={(Object.values(itz)[0] as any / surveysCompleted) * 100}
                                                  format={() => `${Object.keys(itz)[0]} - ${Object.values(itz)[0]}`}
                                                  className={'mb-2'}
                                                  percentPosition={{
                                                      align: 'start',
                                                      type: 'outer'
                                                  }}/>
                                    ))}
                                </Row> :
                                <Progress key={idx} percent={(item[1] / surveysCompleted) * 100}
                                          format={() => `${item[0]} - ${item[1]}`}
                                          className={'mb-2'}
                                          percentPosition={{align: 'start', type: 'outer'}}/>
                        ))}
                    </Card>
                </Col>
            ))}
        </Row>
    )
}