import React, {useEffect, useState} from "react";
import Resource from "../resources/Resource";
import {format} from "date-fns";
import axios from "axios";
import {toast} from "react-toastify";
import {httpRequest} from "../services/httpRequest";
import CreateNote from "../notes/CreateNote";
import {NoteClass} from "../notes/NoteClass";
import {useHistory} from "react-router-dom";

export default function Contact(props) {
    const [note, setNote] = useState(new NoteClass());
    const history = useHistory();
    const initialState: any = {
        createdAt: format(new Date(), "yyyy-MM-dd'T'HH:mm").toLocaleString(),
        duration: '',
        serviceLocations: [],
        services: [],
        type: [],
        program: 'LYTE Lounge',
    };
    const [contact, setContact] = useState(initialState)

    function contactTypeChange(e) {
        setContact({
            ...contact,
            [e.target.name]: e.target.id
        })
    }

    useEffect(() => {
        if (props.contact) {
            setContact({
                ...props.contact,
                type: props.contact.contact_type,
                serviceLocations: props.contact.service_location,
                createdAt: props.contact.created_at.slice(0, -1),
            });
            setNote(props.contact.note);
        } else {
            setContact(initialState);
            setNote(new NoteClass());
        }
    }, [props.contact]);

    function updateField(e) {
        setContact({
            ...contact,
            [e.target.name]: e.target.value
        })
    }

    function save() {
        const url = props.personId ? `/person/${props.personId}/create-activity` : '/person/create-activity';
        axios.post(`/api/v1${url}`,
            {
                contact,
                note
            },
            httpRequest.getHeaders(),
        ).then((response) => {
            props.updateActivitiesList(response.data);
            setContact(initialState);
            setNote(new NoteClass());
            toast.info(`🦄 Contact saved.`);
        }).catch(error => httpRequest.errorHandler(error, props.history))
    }

    function update() {
        const url = `/person/${contact.person_id}/update-activity/${contact.id}`;
        axios.post(`/api/v1${url}`,
            {
                contact,
                note
            },
            httpRequest.getHeaders(),
        ).then((response) => {
            props.setActivities(response.data);
            setContact(initialState);
            setNote(new NoteClass());
            toast.info(`🦄 Contact updated.`);
            history.push(`/check-in/${contact.person_id}`)
        }).catch(error => httpRequest.errorHandler(error, props.history))
    }

    function handleServiceChange(e, set) {
        const serviceSet = contact[set];

        if (e.target.checked) {
            serviceSet.push(e.target.id);
        } else {
            serviceSet.splice(serviceSet.indexOf(e.target.id), 1)
        }

        setContact({
            ...contact,
            [set]: serviceSet
        })
    }

    function shouldDisableType(name: string) {
        if (contact.type.length === 0) return false;
        return name === 'attempted' && contact.type.indexOf('attempted') < 0;
    }

    return (
        <div className="card">
            <h5 className='card-header'>Contact</h5>
            <div className={'card-body'}>
                <div className={'row'}>
                    <div className={'col-md-5 col-sm-12'}>
                        <h6>Type</h6>
                        <div className={'card-body'}>
                            {[
                                {
                                    name: 'meeting',
                                    value: 'Meeting/conversation with youth'
                                },
                                {
                                    name: 'attempted',
                                    value: 'Attempted contact/sent message (outreach to youth or youth no show)'
                                },
                                {
                                    name: 'advocacy',
                                    value: 'Advocacy (youth not present)'
                                }
                            ].map((item) => {
                                return (
                                    <div className="form-check" key={item.name}>
                                        <input className="form-check-input"
                                               onChange={(e) => handleServiceChange(e, 'type')}
                                               name={'type'}
                                               disabled={shouldDisableType(item.name)}
                                               checked={contact.type.indexOf(item.name) > -1}
                                               type="checkBox" id={item.name}/>
                                        <label className="form-check-label" htmlFor="contact-type">
                                            {item.value}
                                        </label>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                    <div className={'col-md-4 col-sm-12'}>
                        <h6>Info</h6>
                        <div className={'card-body'}>
                            <label className="form-label">Duration (in minutes)</label>
                            <input value={contact.duration}
                                   name={'duration'}
                                   onChange={updateField}
                                   className="form-control" aria-label={'Duration'}/>
                            <label className="form-label">Date of contact</label>
                            <input value={contact.createdAt}
                                   name={'createdAt'}
                                   onChange={updateField}
                                   type='datetime-local'
                                   placeholder='Created at' className="form-control" aria-label="created at"/>
                            <div className="input-group-append"></div>
                        </div>
                    </div>
                    <div className={'col-md-3 col-sm-12'}>
                        <h6>Program</h6>
                        <div className={'card-body'}>
                            <div className={'row'}>
                                {['LYTE Lounge', 'Lytehouse Apartments'].map((item) => {
                                    return (<div className="form-check" key={item}>
                                        <input className="form-check-input" onChange={contactTypeChange}
                                               checked={contact.program === item}
                                               name={'program'} type="radio" id={item}/><label
                                        className="form-check-label" htmlFor="program">{item}</label>
                                    </div>)
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <h5 className={'card-header'}>Service Location</h5>
                        <div className={'card-body'}>
                            <div className={'row'}>
                                {['LYTE Lounge', 'Youth\'s Home', "Café/Restaurant", 'Hospital/Health Center', 'Other Community Agency', 'School', 'Phone', 'Remote', 'Social Media', 'Text', 'Email', 'Other'].map((item) => {
                                    return (<div className={'col-6'} key={item}>
                                        <div className="form-check">
                                            <input className="form-check-input"
                                                   onChange={(e) => handleServiceChange(e, 'serviceLocations')}
                                                   checked={contact.serviceLocations.indexOf(item) > -1}
                                                   name={'serviceLocations'} type="checkBox" id={item}/><label
                                            className="form-check-label" htmlFor="serviceLocations">{item}</label>
                                        </div>
                                    </div>)
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'col-12'} hidden={contact.type.length === 0 || contact.type.includes('attempted')}>
                        <h5 className="card-header">Services</h5>
                        <div className="card-body">
                            <div className="col-12">
                                <div className="row">
                                    {props.resourceList.map((resource) =>
                                        <Resource key={resource.id}
                                                  handleServiceChange={handleServiceChange}
                                                  contact={contact}
                                                  data={resource} personId={props.personId}/>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <h5 className={'card-header'}>Notes</h5>
                        <div className={'card-body'}>
                            <CreateNote note={note} setNote={setNote} saveNote={save}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mb-2">
                <button onClick={contact.id ? update : save}
                        disabled={!contact.type || !note.note}
                        className="btn btn-outline-primary btn-sm btn-block"
                        type="button">{contact.id ? 'Update contact' : 'Save contact'}
                </button>
            </div>
        </div>
    )
}