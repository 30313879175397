export default class PersonInterface {
    dob: string | "";
    email: string | "";
    name: string | "";
    first_name: string | "";
    locale: string[] | [];
    last_name: string | "";
    pronouns: string | "";
    race: string | "";
    sexual_orientation: string | "";
    immigrant_refugee: boolean | false;
    gender: string | "";
    parenting: string | "";
    address: string | "";
    phone: string | "";
    social: string | "";
    emergency_contact_name: string | "";
    emergency_contact_number: string | "";
    emergency_contact_relationship: string | "";
    assigned: boolean | false;
}

export var PersonEditOptions = [
    {field: "name", display: "Preferred Name", class: "col-md-12 mb-1", type: "text"},
    {field: "pronouns", display: "Gender Pronouns", class: "col-md-6 mb-1", type: "text"},
    {
        field: "race", display: "Race/Ethnicity", class: "form-group col-md-6", type: "radio",
        options: [
            {id: 'no-report', display: 'Not Reported'},
            {id: 'unknown', display: 'Unknown'},
            {id: 'am-ind-alsk-nat', display: 'American Indian or Alaskan Native'},
            {id: 'asian', display: 'Asian'},
            {id: 'black-af-am', display: 'Black/African American'},
            {id: 'black-latx', display: 'Black & Latinx'},
            {id: 'black-wht', display: 'Black & White'},
            {id: 'latinx', display: 'Latinx'},
            {id: 'latx-wht', display: 'Latinx & White'},
            {id: 'multiracial', display: 'Multiracial'},
            {id: 'white', display: 'White'},
        ]
    },
    {
        field: "sexual_orientation", display: "Sexual Orientation", class: "form-group col-md-6", type: "radio",
        options: [
            {id: 'no-report', display: 'Not reported'},
            {id: 'asexual', display: 'Asexual'},
            {id: 'bisexual', display: 'Bisexual'},
            {id: 'gay', display: 'Gay'},
            {id: 'heterosexual', display: 'Heterosexual'},
            {id: 'lesbian', display: 'Lesbian'},
            {id: 'pansexual', display: 'Pansexual'},
            {id: 'queer', display: 'Queer'},
            {id: 'questioning', display: 'Questioning'},
            {id: 'not-listed', display: 'Identity not listed'},
            {id: 'no-answer', display: 'Prefer not to answer'},
        ]
    },
    {
        field: 'parenting', display: 'Are you parenting?', class: 'form-group col-md-6', type: 'radio', options: [
            {id: 'idk', display: "Don't know"},
            {id: 'yes', display: 'Yes'},
            {id: 'no', display: 'No'},
        ]
    },
    {
        field: 'parenting_details',
        display: 'If you are parenting, Tell us about your children (names and ages)',
        class: 'col-md-12 mb-1',
        type: 'textarea'
    },
    {
        field: 'locale',
        display: 'Where are you from?',
        class: 'col-md-12 mb-1',
        type: 'checkbox',
        options: [
            {id: 'south-side', display: 'South Side'},
            {id: 'west-side', display: 'West Side'},
            {id: 'north-side', display: 'North Side'},
            {id: 'suburbs', display: 'Chicago Suburbs'},
            {id: 'out-of-state', display: 'Out of State'},
            {id: 'no-answer', display: 'Prefer not to answer'},
        ]
    },
    {
        field: "reference",
        display: "How did you hear about us?",
        class: 'col-md-12 mb-1',
        type: 'checkbox',
        options: [
            {id: 'family', display: 'Family member'},
            {id: 'friend', display: 'Friend'},
            {id: 'internet', display: 'Internet search'},
            {id: 'organization', display: 'Organization'},
            {id: 'worship', display: 'Place of worship'},
            {id: 'social-media', display: 'Social media'},
            {id: 'other', display: 'Other'},
            {id: 'unknown', display: "Don't know"},
        ]
    },
    {
        field: 'primary_phone',
        display: 'Primary phone #',
        class: 'col-md-12 mb-1',
        type: 'text'
    },
    {
        field: "text_main",
        display: "Texting service enabled",
        class: 'col-md-12 mb-1',
        type: 'radio',
        options: [
            {id: 'true', display: 'Yes'},
            {id: 'false', display: 'No'},
            {id: 'not-checked', display: 'Not Checked'},
        ]
    },
    {
        field: 'primary_phone_notes',
        display: 'Primary phone notes',
        class: 'col-md-12 mb-1',
        type: 'textarea'
    },
    {
        field: 'secondary_phone',
        display: 'Secondary phone #',
        class: 'col-md-12 mb-1',
        type: 'text'
    },
    {
        field: "text_secondary",
        display: "Texting service enabled",
        class: 'col-md-12 mb-1',
        type: 'radio',
        options: [
            {id: 'true', display: 'Yes'},
            {id: 'false', display: 'No'},
            {id: 'not-checked', display: 'Not Checked'},
        ]
    },
    {
        field: 'secondary_phone_notes',
        display: 'Secondary phone notes',
        class: 'col-md-12 mb-1',
        type: 'textarea'
    },
    {
        field: "undocumented", display: "Are you undocumented?", class: "form-group col-md-8 mb-2", type: "radio",
        options: [
            {id: 'unknown', display: "Don't Know"},
            {id: false, display: "No"},
            {id: true, display: "Yes"},
        ]
    },
    {
        field: 'hmis',
        display: 'HMIS#',
        class: 'col-md-4 mb-1',
        type: 'text'
    },
    {
        field: "gender", display: "Gender Identity", class: "form-group col-md-12", type: "radio",
        options: [
            {id: "no-report", display: "Not reported"},
            {id: "female", display: "Female"},
            {id: "male", display: "Male"},
            {id: "non-binary", display: "Non-binary/gender nonconforming"},
            {id: "agender", display: "Agender"},
            {id: "genderqueer-afluid", display: "Genderqueer/gender fluid"},
            {id: "trans", display: "Trans"},
            {id: "trans-man", display: "Trans Man"},
            {id: "trans-woman", display: "Trans Woman"},
            {id: "not-listed", display: "Identity not listed"},
            {id: "no-answer", display: "Prefer not to answer"},
        ]
    },
    {field: "first_name", display: "First Name", class: "col-md-6", type: "text"},
    {field: "last_name", display: "Last Name", class: "col-md-6", type: "text"},
    {field: "dob", display: "Date of Birth", class: "col-md-12", type: "date"},
    {field: "address", display: "Address", class: "col-md-12", type: "text"},
    {field: "mailing_address", display: "Mailing address", class: "col-md-12", type: "text"},
    {field: "email", display: "Email", class: "col-md-12 mb-1", type: "email"},
    {field: "social", display: "Social Link", class: "col-md-6", type: "text"},
    {
        field: "preferred_contact", display: "Preferred contact", class: "form-group col-md-6", type: "radio",
        options: [
            {id: "email", display: "Email"},
            {id: "phone", display: "Phone"},
            {id: "text", display: "Text"},
            {id: "facebook", display: "Facebook"},
            {id: "other", display: "Other social media"},
        ]
    },
    {field: "emergency_contact_name", display: "Emergency Contact Name", class: "col-md-12 mb-1", type: "text"},
    {field: "emergency_contact_number", display: "Emergency Contact Number", class: "col-md-12 mb-1", type: "text"},
    {
        field: "emergency_contact_relationship",
        display: "Emergency Contact Relationship",
        class: "col-md-12 mb-1",
        type: "text"
    },
    {
        field: "notes",
        display: 'Additional Info/Notes',
        class: 'col-md-12 mb-1',
        type: 'textarea'
    },
    {
        field: "assign",
        display: "Please assign LYTE buddy/advocate",
        class: "col-md-12",
        type: 'checkbox-single',
        options: [
            {id: true, display: 'Please assign'},
        ]
    },
    {
        field: "opt_out_comm",
        display: "Opt out of communications",
        class: "col-md-12",
        type: 'checkbox-single',
        options: [
            {id: true, display: 'Opt Out'},
        ]
    }
];