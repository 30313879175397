import React, {useState} from "react";
import {dateSort} from "../services/helpers";
import ResourceList from "../resources/ResourceList";
import {useHistory} from 'react-router-dom';

export default function ActivityLog(props: any) {
    const [maxDisplay, setMaxDisplay] = useState(5);
    const history = useHistory();

    const handleEdit = (activityId: any) => {
        history.push(`/check-in/edit/${activityId}`);
    }

    return (
        <div key={props.index}>
            <h5 className="card-header">Contact
                Log {props.activities.length ? `(${props.activities.length} Recorded Events)` : null}</h5>
            <div className="card-body">
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Services Provided</th>
                        <th scope="col">Entered by</th>
                    </tr>
                    </thead>
                    {
                        props.activities.sort((a, b) => {
                            return dateSort(a, b);
                        }).reverse().map((activity, index) => {
                                return (
                                    <ResourceList activity={activity}
                                                  maxDisplay={maxDisplay}
                                                  key={index}
                                                  navigate={handleEdit}
                                                  note={activity.note}
                                                  index={index}/>
                                )
                            }
                        )}
                    <tbody>
                        <tr hidden={props.activities.length}>
                            <td>No recent activity.</td>
                        </tr>
                    </tbody>
                </table>
                <button hidden={props.activities.length <= maxDisplay}
                        onClick={() => setMaxDisplay(maxDisplay + 5)} className="btn btn-block btn-primary">
                    Show More
                </button>
                <button hidden={maxDisplay === 5}
                        onClick={() => setMaxDisplay(maxDisplay - 5)} className="btn btn-block btn-outline-secondary">
                    Show Less
                </button>
            </div>
        </div>
    );
}