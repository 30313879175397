import React from "react"
import {Button, Card, Checkbox, Col, Input, Radio, Row} from "antd";
import {MinusCircleOutlined} from "@ant-design/icons";

export const ItemOptions = (
    {
        items,
        item,
        updateOptionItem,
        removeFromOptionSelect,
        addQuestionDependency,
        addToOptionSelect
    }) => {
    return (<>
        {item.type === 'input' &&
            <Input placeholder={'Short answer text'}/>
        }
        {(item.type === 'select' || item.type === 'checkbox') &&
            (<Row>
                {item.options?.map((option: any) => (
                    <Col xs={24} key={option.order} className={'mb-1'}>
                        <Row gutter={16}>
                            <Col xs={1}>
                                {item.type === 'select' && <Radio/>}
                                {item.type === 'checkbox' && <Checkbox/>}
                            </Col>
                            <Col xs={19}>
                                <Input
                                    width={'100%'}
                                    size={'small'}
                                    placeholder={'Description'}
                                    value={option.description}
                                    onChange={(e) => updateOptionItem(item.order, 'description', e.target.value, option.order, 'options')}/>
                            </Col>
                            <Col xs={4}>
                                <Row>
                                    <Col xs={6}>
                                        <Button
                                            size={'small'}
                                            type={'primary'}
                                            onClick={() => removeFromOptionSelect(item.order, option.order, 'options')}><MinusCircleOutlined/></Button>
                                    </Col>
                                    {!item.dependentQuestion && items.filter((q: any) => (q.dependentResponse === option.order && q.dependentQuestion === item.order)).length === 0 &&
                                        <Col xs={18}>
                                            <Button
                                                size={'small'}
                                                onClick={() => addQuestionDependency(item.order, option.order)}
                                            >Add dependency</Button>
                                        </Col>}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                ))}
                <Col xs={24} className={'mt-2'}>
                    <Button
                        onClick={() => addToOptionSelect(item.order, 'options')}>Add
                        Option</Button>
                </Col>
            </Row>)
        }
        {item.type === 'grid' &&
            <Row gutter={8}>
                <Col xs={12}>
                    <Card title={'Rows'}>
                        {item.rows?.map((row: any) => (
                            <Row key={row.order} gutter={16}
                                 className={'mb-2'}>
                                <Col xs={20}>
                                    <Input key={row.order}
                                           value={row.description}
                                           size={'small'}
                                           onChange={(e) => updateOptionItem(item.order, 'description', e.target.value, row.order, 'rows')}/>
                                </Col>
                                <Col xs={4}>
                                    <Button
                                        size={'small'}
                                        type={'primary'}
                                        onClick={() => removeFromOptionSelect(item.order, row.order, 'rows')}><MinusCircleOutlined/></Button>
                                </Col>
                            </Row>
                        ))
                        }
                        <Col xs={24}>
                            <Button
                                onClick={() => addToOptionSelect(item.order, 'rows')}
                            >Add row</Button>
                        </Col>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card title={'Columns'}>
                        {item.columns?.map((column: any) => (
                            <Row key={column.order} gutter={16}
                                 className={'mb-2'}>
                                <Col xs={2}>
                                    <Radio/>
                                </Col>
                                <Col xs={18}>
                                    <Input key={column.order}
                                           value={column.description}
                                           size={'small'}
                                           onChange={(e) => updateOptionItem(item.order, 'description', e.target.value, column.order, 'columns')}/>
                                </Col>
                                <Col xs={4}>
                                    <Button
                                        size={'small'}
                                        type={'primary'}
                                        onClick={() => removeFromOptionSelect(item.order, column.order, 'columns')}><MinusCircleOutlined/></Button>
                                </Col>
                            </Row>
                        ))
                        }
                        <Col xs={24}>
                            <Button
                                onClick={() => addToOptionSelect(item.order, 'columns')}>Add
                                Column</Button>
                        </Col>
                    </Card>
                </Col>
            </Row>
        }
    </>)
}