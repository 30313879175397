import {Button, Card, Col, Divider, Input, List, Row, Select, Space, Typography} from "antd";
import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {ItemOptions} from "./ItemOptions";

export const SurveyEditor = ({survey, setSurvey}) => {
    const addToForm = () => {
        setSurvey({
            ...survey,
            questions: [
                ...survey.questions || [],
                {
                    order: ((survey.questions?.map((d: any) => d.order).sort((a: any, b: any) => b - a) || [0])[0] || 0) + 1
                }
            ]
        })
    }

    const removeItem = (order: any) => {
        let questions = survey.questions.map((q: any) => q.order === order ? null : q).filter((n: any) => n);
        questions = questions.filter((q: any) => q.dependentQuestion !== order);

        setSurvey({
            ...survey,
            questions
        })
    }

    const removeFromOptionSelect = (order: any, optionOrder: any, key: any) => {
        let optionItem = survey.questions.find((q: any) => q.order === order);
        optionItem = {
            ...optionItem,
            [key]: optionItem[key].map((q: any) => q.order === optionOrder ? null : q).filter((a: any) => a)
        }

        setSurvey({
            ...survey,
            questions: survey.questions.map((q: any) => q.order === order ? optionItem : q)
        })
    }

    const addQuestionDependency = (item: any, option: any) => {
        setSurvey({
            ...survey,
            questions: [
                ...survey.questions || [],
                {
                    order: (survey.questions?.map((d: any) => d.order).sort((a: any, b: any) => b - a) || [0])[0] + 1,
                    dependentQuestion: item,
                    dependentResponse: option,
                }
            ]
        })
    }

    const addToOptionSelect = (order: any, key: any) => {
        let optionItem = survey.questions.find((q: any) => q.order === order);
        optionItem = {
            ...optionItem,
            [key]: [
                ...optionItem[key] || [],
                {
                    order: (optionItem[key]?.map((d: any) => d.order).sort((a: any, b: any) => b - a) || [0])[0] + 1
                }
            ]
        }

        setSurvey({
            ...survey,
            questions: survey.questions.map((q: any) => q.order === order ? optionItem : q)
        });
    }

    const updateQuestion = (order: any, key: any, value: any) => {
        let foundItem = survey.questions.find((item: any) => item.order === order);

        foundItem = {
            ...foundItem,
            [key]: value
        };

        setSurvey({
            ...survey,
            questions: survey.questions.map((item: any) => item.order === order ? foundItem : item)
        })
    }

    const updateOptionItem = (order: any, key: any, value: any, optionOrder: any, itemKey: any) => {
        let optionItem = survey.questions.find((q: any) => q.order === order);
        let optionUpdateItem = optionItem[itemKey].find((q: any) => q.order === optionOrder);

        optionUpdateItem = {
            ...optionUpdateItem,
            [key]: value
        }

        optionItem = {
            ...optionItem,
            [itemKey]: optionItem[itemKey].map((opt: any) => opt.order === optionOrder ? optionUpdateItem : opt)
        }

        setSurvey({
            ...survey,
            questions: survey.questions.map((q: any) => q.order === order ? optionItem : q)
        });
    }

    return (
        <Col xs={24} className={'mb-2'}>
            <Card title={survey.name}>
                <Row>
                    <Col xs={24}>
                        <Typography.Title level={5}>Form Data</Typography.Title>
                    </Col>
                    <Col xs={4}>
                        <Typography.Text>Description:</Typography.Text>
                    </Col>
                    <Col xs={20}>
                        <TextArea defaultValue={survey.description}
                                  autoSize={{minRows: 3}}
                                  onChange={(e) => setSurvey({...survey, description: e.target.value})}/>
                    </Col>
                </Row>
                <Divider/>
                <Row>
                    <Col xs={24}>
                        <Typography.Title level={5}>Questions</Typography.Title>
                    </Col>
                    <Col xs={24}>
                        <List
                            dataSource={(survey.questions || [])?.filter((a: any) => !a.dependentQuestion).sort((a: any, b: any) => a.order - b.order)}
                            renderItem={(item: any, idx1: any) => {
                                return (
                                    <Row className={'mb-2'} key={idx1}>
                                        <Col xs={24}>
                                            <Card
                                                title={<Input
                                                    className={'mb-2'}
                                                    variant={'borderless'}
                                                    value={item.description}
                                                    onChange={(e) => updateQuestion(item.order, 'description', e.target.value)}
                                                    placeholder={'Question Description'}/>
                                                }
                                                extra={[<Select placeholder={'Question Type'}
                                                                onChange={(value) => updateQuestion(item.order, 'type', value)}
                                                                value={item.type}
                                                                options={[
                                                                    {
                                                                        value: 'input',
                                                                        label: 'Short answer'
                                                                    },
                                                                    {
                                                                        value: 'select',
                                                                        label: 'Multiple choice'
                                                                    },
                                                                    {
                                                                        value: 'checkbox',
                                                                        label: 'Checkboxes'
                                                                    },
                                                                    {
                                                                        value: 'grid',
                                                                        label: 'Multiple choice grid'
                                                                    },
                                                                ]}/>,
                                                    <Button type={'primary'} className={'ml-1'}
                                                            onClick={() => removeItem(item.order)}><DeleteOutlined/></Button>]
                                                }
                                            >
                                                <ItemOptions
                                                    items={survey.questions}
                                                    item={item}
                                                    addQuestionDependency={addQuestionDependency}
                                                    updateOptionItem={updateOptionItem}
                                                    removeFromOptionSelect={removeFromOptionSelect}
                                                    addToOptionSelect={addToOptionSelect}
                                                    key={item.order}
                                                />
                                            </Card>
                                        </Col>
                                        <Col xs={23} offset={1} className={'mt-2'}>
                                            {survey.questions.filter((a: any) => a.dependentQuestion === item.order).map((question: any, index: any) => {
                                                return (<Card
                                                        key={index}
                                                        title={<Space direction={'vertical'}>
                                                            <Typography.Text>User answered: {survey.questions.find((q: any) => q.order === question.dependentQuestion).options.find((opt: any) => opt.order === question.dependentResponse).description}</Typography.Text>
                                                            <Input
                                                                className={'mb-2'}
                                                                variant={'borderless'}
                                                                value={question.description}
                                                                onChange={(e) => updateQuestion(question.order, 'description', e.target.value)}
                                                                placeholder={'Question Description'}/>
                                                        </Space>
                                                        }
                                                        extra={[<Select placeholder={'Question Type'}
                                                                        onChange={(value) => updateQuestion(question.order, 'type', value)}
                                                                        value={question.type}
                                                                        options={[
                                                                            {
                                                                                value: 'input',
                                                                                label: 'Short answer'
                                                                            },
                                                                            {
                                                                                value: 'select',
                                                                                label: 'Multiple choice'
                                                                            },
                                                                            {
                                                                                value: 'checkbox',
                                                                                label: 'Checkboxes'
                                                                            },
                                                                            {
                                                                                value: 'grid',
                                                                                label: 'Multiple choice grid'
                                                                            },
                                                                        ]}/>,
                                                            <Button type={'primary'} className={'ml-1'}
                                                                    onClick={() => removeItem(question.order)}><DeleteOutlined/></Button>]
                                                        }
                                                    >
                                                        <ItemOptions
                                                            items={survey.questions}
                                                            item={question}
                                                            addQuestionDependency={addQuestionDependency}
                                                            updateOptionItem={updateOptionItem}
                                                            removeFromOptionSelect={removeFromOptionSelect}
                                                            addToOptionSelect={addToOptionSelect}
                                                            key={item.order}
                                                        />
                                                    </Card>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                )
                            }}
                        />
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    <Col xs={24} sm={8}>
                        <Button type={'primary'} block onClick={() => addToForm()}>Add Question</Button>
                    </Col>
                </Row>
            </Card>
        </Col>
    )
}