import React, {useEffect, useState} from "react";
import {Card, Col, DatePicker, Progress, Result, Row, Select, Space, Statistic} from "antd";
import dayjs from "dayjs";
import axios from "axios";
import {httpRequest} from "../services/httpRequest";

export const Analytics = ({history}: any) => {
    const [month, setMonth] = useState(dayjs().startOf('M'));
    const [to, setTo] = useState(dayjs().endOf('M'));
    const [loading, setLoading] = useState(false);
    const [loadingCheckIns, setLoadingCheckIns] = useState(false);
    const [loadingContactData, setLoadingContactData] = useState(false);
    const [loadingYouthData, setLoadingYouthData] = useState(false);
    const [contactData, setContactData] = useState({} as any);
    const [checkInData, setCheckInData] = useState({} as any);
    const [youthData, setYouthData] = useState({} as any);
    const [loadingResources, setLoadingResources] = useState(false);
    const [analytics, setAnalytics] = useState({} as any);
    const [resourceData, setResourceData] = useState(null);
    const [resources, setResources] = useState([]);
    const [selectedResource, setSelectedResource] = useState(null);

    useEffect(() => {
        setLoading(true);
        setLoadingContactData(true);
        setLoadingCheckIns(true);
        setLoadingYouthData(true);

        axios.get(`/api/v1/analytics?from=${month.toISOString()}&to=${to.toISOString()}}`,
            httpRequest.getHeaders())
            .then(response => {
                setAnalytics(response.data);
            })
            .catch(error => httpRequest.errorHandler(error, history))
            .finally(() => {
                setLoading(false);
            });
        axios.get(`/api/v1/analytics?from=${month.toISOString()}&to=${to.toISOString()}}&type=contact-data`,
            httpRequest.getHeaders())
            .then(response => {
                setContactData(response.data)
            })
            .catch(error => httpRequest.errorHandler(error, history))
            .finally(() => {
                setLoadingContactData(false);
            });
        axios.get(`/api/v1/analytics?from=${month.toISOString()}&to=${to.toISOString()}}&type=check-ins`,
            httpRequest.getHeaders())
            .then(response => {
                setCheckInData(response.data)
            })
            .catch(error => httpRequest.errorHandler(error, history))
            .finally(() => {
                setLoadingCheckIns(false);
            });
        axios.get(`/api/v1/analytics?from=${month.toISOString()}&to=${to.toISOString()}}&type=youth-data`,
            httpRequest.getHeaders())
            .then(response => {
                setYouthData(response.data)
            })
            .catch(error => httpRequest.errorHandler(error, history))
            .finally(() => {
                setLoadingYouthData(false);
            });
    }, [month, to]);

    useEffect(() => {
        if (selectedResource) {
            setLoadingResources(true);

            axios.get(`/api/v1/analytics/${selectedResource}/resources?from=${month.toISOString()}&to=${to.toISOString()}}`,
                httpRequest.getHeaders())
                .then(response => {
                    setResourceData(response.data);
                })
                .catch(error => httpRequest.errorHandler(error, history))
                .finally(() => {
                    setLoadingResources(false);
                });
        }
    }, [selectedResource, month, to]);

    useEffect(() => {
        axios.get(`api/v1/resource-list/list`,
            httpRequest.getHeaders())
            .then(response => {
                setResources(response.data);
            })
            .catch(error => httpRequest.errorHandler(error, history))
    }, []);

    const getColor = (currentValue: any) => {
        if (currentValue > 100)
            return '#3f8600';
        if (currentValue < 10)
            return '#cf1322'
        return '#585858';
    }

    return (
        <Col xs={24}>
            <Card title={`Reports`}
                  className={'mt-3'}
                  extra={[<span className={'font-weight-bold'} key={'from'}>From: <DatePicker allowClear={false} format={`MMM - 'YY`} value={month}
                                                               onChange={setMonth} picker="month"/></span>,
                      <span className={'ml-2 font-weight-bold'} key={'to'}>To: <DatePicker allowClear={false} format={`MMM - 'YY`}
                                                                          value={to} onChange={setTo}
                                                                          picker="month"/></span>]}>
                <Row gutter={16}>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card loading={loadingCheckIns} title={'Youth Check-Ins'}>
                            <Statistic
                                title={checkInData?.header}
                                value={checkInData?.value}
                                valueStyle={{color: getColor(checkInData?.value)}}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card loading={loadingYouthData} title={'Youth New to Lyte'}>
                            <Statistic
                                title={youthData?.header}
                                value={youthData?.value}
                                valueStyle={{color: getColor(youthData?.value)}}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card loading={loading} title={'Services'}>
                            <Space size={'large'} align={'center'}>
                                <Statistic
                                    title={analytics.totalActivities?.header}
                                    value={analytics.totalActivities?.value}
                                    valueStyle={{color: getColor(analytics.totalActivities?.value)}}
                                />
                                <Statistic
                                    title={analytics.uniqueActivities?.header}
                                    value={analytics.uniqueActivities?.value}
                                    valueStyle={{color: getColor(analytics.uniqueActivities?.value)}}
                                />
                            </Space>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card loading={loadingContactData} title={'Contacts'}>
                            <Space size={'large'} align={'center'}>
                                <Statistic
                                    title={contactData.totalYouth?.header}
                                    value={contactData.totalYouth?.value}
                                    valueStyle={{color: getColor(contactData.totalYouth?.value)}}
                                />
                                <Statistic
                                    title={contactData.uniqueYouth?.header}
                                    value={contactData.uniqueYouth?.value}
                                    valueStyle={{color: getColor(contactData.uniqueYouth?.value)}}
                                />
                            </Space>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card title={'Top Services'} loading={loading}>
                            {analytics.topActivities?.data.map((activity: any) => {
                                return (
                                    <Progress
                                        key={activity.name}
                                        className={'mb-2'}
                                        percent={(activity.value / (analytics.totalActivities.value || 100)) * 100}
                                        format={() => `${activity.name} - ${activity.value}`}
                                        percentPosition={{align: 'start', type: 'outer'}}
                                        size={['100%', 15]}
                                        strokeColor={`#${activity.color}`}
                                    />
                                )
                            })}
                            {analytics.topActivities?.data.length === 0 &&
                                <Result
                                    title="No data to display for this time period."
                                />
                            }
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card title={'Youth Contacts by Sexual Orientation'} loading={loadingContactData}>
                            {contactData.youthData?.data.map((activity: any) => {
                                return (
                                    <Progress
                                        key={activity.name}
                                        className={'mb-2'}
                                        percent={(activity.value / (contactData.totalYouth?.value || 100)) * 100}
                                        format={() => `${activity.name} - ${activity.value}`}
                                        percentPosition={{align: 'start', type: 'outer'}}
                                        size={['100%', 15]}
                                        strokeColor={`#${activity.color}`}
                                    />
                                )
                            })}
                            {contactData.youthData?.data.length === 0 &&
                                <Result
                                    title="No data to display for this time period."
                                />
                            }
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card title={'Youth Contacts by Race/Ethnicity'} loading={loadingContactData}>
                            {contactData.ethnicityData?.data.map((activity: any) => {
                                return (
                                    <Progress
                                        key={activity.name}
                                        className={'mb-2'}
                                        percent={(activity.value / (contactData.totalYouth?.value || 100)) * 100}
                                        format={() => `${activity.name} - ${activity.value}`}
                                        percentPosition={{align: 'start', type: 'outer'}}
                                        size={['100%', 15]}
                                        strokeColor={`#${activity.color}`}
                                    />
                                )
                            })}
                            {contactData.ethnicityData?.data.length === 0 &&
                                <Result
                                    title="No data to display for this time period."
                                />
                            }
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} className={'mb-2'}>
                        <Card title={'Youth Contacts by Gender'} loading={loadingContactData}>
                            {contactData.genderData?.data.map((activity: any) => {
                                return (
                                    <Progress
                                        key={activity.name}
                                        className={'mb-2'}
                                        percent={(activity.value / (contactData.totalYouth?.value || 100)) * 100}
                                        format={() => `${activity.name} - ${activity.value}`}
                                        percentPosition={{align: 'start', type: 'outer'}}
                                        size={['100%', 15]}
                                        strokeColor={`#${activity.color}`}
                                    />
                                )
                            })}
                            {contactData.genderData?.data.length === 0 &&
                                <Result
                                    title="No data to display for this time period."
                                />
                            }
                        </Card>
                    </Col>
                    <Col xs={24}>
                        <Card title={'Service Data'} loading={loadingResources}
                              extra={<Select
                                  placeholder={'Select a service'}
                                  style={{width: '100%'}}
                                  showSearch
                                  optionFilterProp={'label'}
                                  defaultValue={selectedResource}
                                  onChange={setSelectedResource}
                                  options={resources.map((item: any) => {
                                      return {
                                          label: item.name,
                                          value: item.id
                                      }
                                  })}/>}
                        >
                            {resourceData &&
                                <Statistic
                                    loading={loadingResources}
                                    title={resources.find((res: any) => res.id === selectedResource).name}
                                    value={resourceData.value}
                                    valueStyle={{color: getColor(resourceData.value)}}
                                />
                            }
                            {!resourceData &&
                                <Result
                                    title="No data to display for this time period/resource combination."
                                />
                            }
                        </Card>
                    </Col>
                </Row>
            </Card>
        </Col>
    )
}