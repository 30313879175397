import React, {useEffect, useState} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import {httpRequest} from "../services/httpRequest";

export default function InfoSheet(props) {
    const listOfQuestions = ['SNAP benefits', 'Medicaid', 'Other health insurance', 'TANF', 'WIC', 'SSI/SSDI',
        'State ID', 'Social security card', 'birth certificate', 'phone', 'wifi access', 'housing', 'a job',
        'a doctor', 'a therapist or counselor', 'a place to hangout and do things you love', 'childcare', 'legal support',
        "your children's documents", 'are you in school'];
    const [current, setCurrent] = useState([]);
    const url = `/person/${props.personId}/intake`;

    useEffect(() => {
        axios.get(`/api/v1${url}`,
            httpRequest.getHeaders(),
        ).then((response) => {
            setCurrent(response.data.intake);
        }).catch(error => httpRequest.errorHandler(error, props.history))
    }, [])

    function formChange(e) {
        const changeSet = current;

        if (e.target.checked) {
            changeSet.push(e.target.id);
        } else {
            changeSet.splice(changeSet.indexOf(e.target.id), 1)
        }

        setCurrent(changeSet);

        axios.post(`/api/v1${url}`,
            {intake: changeSet},
            httpRequest.getHeaders(),
        ).then((response) => {
            setCurrent(response.data.intake);
            toast.success('Response sucessfully saved.');
        }).catch(error => httpRequest.errorHandler(error, props.history))
    }

    return (
        <div>
            <h5 className="card-header">Other Info</h5>
            <div className="card-body">
                <h6>Do you currently have?</h6>
                <div className={'col-12'}>
                    <div className={'row'}>
                        {listOfQuestions.map((item) => {
                            return (<div className="form-check col-sm-12 col-md-6" key={item}>
                                <input className="form-check-input" onChange={formChange}
                                       checked={current.indexOf(item) > -1}
                                       name={'program'} type="checkbox" id={item}/><label
                                className="form-check-label" htmlFor="item">{item}</label>
                            </div>)
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}