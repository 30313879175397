import {
    Button,
    Card,
    Checkbox,
    Col,
    Image,
    Input,
    List,
    Radio,
    Result,
    Row,
    Space,
    Spin,
    Typography
} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
// @ts-ignore
import Logo from '../../../assets/images/logo-green-main.png';
import {SurveyOptions} from "./SurveyOptions";

export const TakeSurvey = ({match}) => {
    const [id] = useState(match ? match.params.id : null);
    const [surveyData, setSurveyData] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [personData, setPersonData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id) {
            setLoading(true);
            axios.get(`/api/v1/public/survey/${id}`)
                .then((result) => {
                    setSurveyData(result.data.survey);
                    setResponseData(result.data.surveyResponse);
                    setPersonData(result.data.person);
                }).catch((error) => toast.error(error))
                .finally(() => setLoading(false))
        }
    }, [match]);

    const updateQuestion = (item: any, value: any) => {
        setResponseData({
            ...responseData,
            data: responseData.data.map((resp: any) => resp.order === item ? {
                ...resp,
                value
            } : resp)
        });
    }

    const updateGridQuestion = (item: any, rowOrder: any, value: any) => {
        setResponseData({
            ...responseData,
            data: responseData.data.map((resp: any) => resp.order === item ? {
                ...resp,
                rows: resp.rows.map((row: any) => row.order === rowOrder ? {
                    ...row,
                    value
                } : row)
            } : resp)
        })
    }

    const updateCheckBox = (item: any, itemId: any, checked: any) => {
        setResponseData({
            ...responseData,
            data: responseData.data.map((resp: any) => resp.order === item ? {
                ...resp,
                // @ts-ignore
                value: checked ? [...new Set([...resp.value || [], itemId])] : (resp.value || []).filter((a: any) => a !== itemId)
            } : resp)
        })
    }

    const save = () => {
        setLoading(true);
        axios.post(`/api/v1/public/survey/${id}`, {data: responseData.data})
            .then((result) => {
                toast.success('Survey results saved');
                setResponseData({
                    ...responseData,
                    completed: result.data.completed,
                    started: true
                })
            }).catch((error) => toast.error(error))
            .finally(() => setLoading(false));
    }

    return (
        <Col xs={24} className={'mt-4'}>
            {!match.params.isAdmin && <Image className={'mb-4 center'} src={Logo} preview={false} width={150}/>}
            <Spin spinning={loading}>
                {!surveyData &&
                    <Card>
                        <Result
                            status={'error'}
                            title={'No survey found.'}
                        />
                    </Card>
                }
                {
                    responseData &&
                    <Card styles={{
                        actions: {
                            padding: '8px'
                        }
                    }}
                          loading={loading}
                          actions={[<Button className={'my-1'} block={true}
                                            disabled={responseData.completed || responseData.expired}
                                            onClick={save}
                                            type={'primary'}>Save</Button>]}
                    >
                        <Card.Meta
                            title={`${surveyData.name} for ${personData?.name}`}
                            description={surveyData.description}
                            className={'mb-2'}
                        />
                        {responseData.expired && !match.params.isAdmin &&
                            <Result
                                status={'warning'}
                                title={'This survey has expired. Thank you for trying though!'}
                            />
                        }
                        {responseData.completed && !match.params.isAdmin &&
                            <Result
                                status={'success'}
                                title={'Thank you for your response, the survey has been submitted!'}
                            />
                        }
                        <Col xs={24}>
                            <List
                                dataSource={(responseData.data || [])?.sort((a: any, b: any) => a.order - b.order).filter((q: any) => !q.dependentQuestion)}
                                renderItem={(item: any) => {
                                    return (
                                        <Row className={'mb-2'} key={item.order}>
                                            <Col xs={24}>
                                                <SurveyOptions
                                                    key={item.order}
                                                    item={item}
                                                    updateGridQuestion={updateGridQuestion}
                                                    responseData={responseData}
                                                    updateCheckBox={updateCheckBox}
                                                    updateQuestion={updateQuestion}
                                                />
                                            </Col>
                                            {responseData.data.filter((q: any) => q.dependentQuestion === item.order).filter((q: any) => responseData.data.find((it2: any) => it2.order === q.dependentQuestion && it2.value === q.dependentResponse)).map((item2: any, index: any) => {
                                                return (
                                                    <Col xs={23} offset={1} key={index} className={'mt-3'}>
                                                        <SurveyOptions
                                                            item={item2}
                                                            updateGridQuestion={updateGridQuestion}
                                                            responseData={responseData}
                                                            updateCheckBox={updateCheckBox}
                                                            updateQuestion={updateQuestion}
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    )
                                }}
                            />
                        </Col>
                    </Card>
                }
            </Spin>
        </Col>
    )
}