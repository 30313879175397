import React from 'react';
import {formatDateNoOffset} from "../services/helpers";

export default function ResourceList(props: any) {
    return (
        <tbody key={props.index} className={props.index >= props.maxDisplay ? 'collapse' : ''}>
            <tr>
                <td>{formatDateNoOffset(props.activity.created_at.slice(0, -1)).toLocaleString()}</td>
                <td>{props.activity.activity_list}</td>
                <td>{props.activity.user}
                    <button onClick={() => props.navigate(props.activity.id)}
                            className={'btn btn-outline-primary btn-sm float-right'}>Edit
                    </button>
                </td>
            </tr>
            <tr style={{margin: '8px', minHeight: 'fit-content'}}
                className={props.index >= props.maxDisplay ? 'collapse' : 'border-0'}>
                <td className={'border-0'}><b>Note:</b></td>
                <td className={'border-0'} style={{whiteSpace: 'break-spaces'}} colSpan={2}>{props.note ? props.note : 'not captured'}</td>
            </tr>
        </tbody>
    )
}