import React from 'react';

export default function PersonListPublic(props: any) {
    return (
        <div className="card-body pt-0">
            <ul className="list-group" hidden={!props.people.length}>
                {props.people.map((person) =>
                    <div className="list-group-item d-flex align-items-center" key={person.id}
                         onClick={() => props.setPerson(person)}>
                        <div className="col-9">
                            {person.name ? person.name : person.first_name}
                            {person.pronouns ? ` (${person.pronouns})` : null}
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <div className="col-md-6 d-none d-md-block">
                                        <span hidden={!person.first_name} className="badge badge-primary badge-pill">
                                    {person.first_name}
                                            {person.last_name ? ` ${person.last_name}` : null}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ul>
            {!props.checkoutPerson && props.term && (
                <div hidden={props.people.length} className="list-group-item d-flex align-items-center" key={'empty'}
                     onClick={() => props.setPerson({})}>
                    <div className="col-9">
                        I don't see my name
                    </div>
                </div>)}
        </div>
    )
}