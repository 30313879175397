import {
    Link, Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import React, {Component} from 'react';
import Home from "./main/Home";
import CheckIn from "./main/CheckIn";
import SignIn from "./main/SignIn";
import {httpRequest} from "./services/httpRequest";
import {toast, ToastContainer} from "react-toastify";
import User from "./users/User";
import axios from "axios";
import SignIntoLyte from "./main/SignIntoLyte";
import CheckedIn from "./main/CheckedIn";
import BriefService from "./main/BriefService";
import Logo from '../../assets/images/logo-green.png';
import CheckedInStatus from "./main/CheckedInStatus";
import FindDuplicates from "./main/FindDuplicates";
import {Analytics} from "./main/Analytics";
import {Surveys} from "./surveys/Surveys";
import {ConfigProvider} from "antd";
import {TakeSurvey} from "./surveys/TakeSurvey";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: httpRequest.getUser() || {}
        }
    }

    logOut(e) {
        e.preventDefault();

        this.setState({user: {}});
        return httpRequest.logOut(this.props.history);
    }

    componentDidMount() {
        if (performance.navigation.type === 1 && httpRequest.getAuthToken()) {
            axios.get(`/api/v1/user`,
                httpRequest.getHeaders())
                .then
                (response => {
                    sessionStorage.setItem('user', JSON.stringify(response.data));
                    this.setState({user: response.data});
                })
                .catch(error => {
                    httpRequest.errorHandler(error, this.props.history)
                });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (httpRequest.getUser()?.sign_in_count === 0) {
            toast.info('You must change your password before continuing', {toastId: 'password-change'});
            return window.location.href = '/#/user';
        }

        if (prevProps.location.pathname !== '/login' || Object.keys(prevState.user || {}).length > 0) return;

        this.setState({
            user: httpRequest.getUser()
        })
    }

    shouldShowNav() {
        return this.props.history.location.pathname === '/login' || this.props.history.location.pathname === '/sign-in' || this.props.history.location.pathname.startsWith('/survey/');
    }

    render() {
        return (
            <div className="container-fluid p-0">
                <nav hidden={this.shouldShowNav()} className="navbar navbar-expand-lg navbar-light bg-light">
                    <a className="navbar-brand" href="#"><img alt={'Lyte Logo'} className={'small-header'} src={Logo}/></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item" hidden={!this.isUserLoggedIn()}>
                                <Link className="nav-link" to="/">Home <span
                                    className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item" hidden={!this.isUserLoggedIn()}>
                                <Link className="nav-link" to="/checked-in">Checked-In List<span
                                    className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item" hidden={!this.isUserLoggedIn()}>
                                <Link className="nav-link" to="/checked-in/status">View Checked-In<span
                                    className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item" hidden={!this.isUserLoggedIn() || !this.state.user.admin}>
                                <Link className="nav-link" to="/reports">Reporting<span
                                    className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item" hidden={!this.isUserLoggedIn() || !this.state.user.admin}>
                                <Link className="nav-link" to="/surveys">Surveys<span
                                    className="sr-only">(current)</span></Link>
                            </li>
                            <li className="nav-item" hidden={!this.isUserLoggedIn() || !this.state.user.admin}>
                                <Link className="nav-link" to="/duplicates/show">Check For Duplicates<span
                                    className="sr-only">(current)</span></Link>
                            </li>
                        </ul>
                        <div className="d-flex" hidden={!this.isUserLoggedIn()}>
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
                                hidden={!this.isUserLoggedIn()}>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                       data-toggle="dropdown" aria-expanded="false">
                                        {this.state.user ? this.state.user.first_name : null}
                                        {this.state.user && this.state.user.last_name ? ` ${this.state.user.last_name}` : null}
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="nav-link" to="/user">Profile</Link>
                                        <a hidden={this.state.user && !this.state.user.admin} className="nav-link"
                                           href={"/admin"}>Admin</a>
                                        <a href="" className="nav-link" onClick={this.logOut.bind(this)}>Logout</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="container-lg">
                    <div className="row">
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: '#86AF48',
                                },
                            }}
                        >
                            <Switch>
                                <Route exact path="/" render={(props) => (
                                    this.isUserLoggedIn() ? (
                                        <Home {...props} />
                                    ) : (
                                        <Redirect to="/login"/>
                                    )
                                )}/>
                                <Route exact path="/login" component={SignIn}/>
                                <Route exact path="/brief-service" component={BriefService}/>
                                <Route exact path="/check-in/:id" component={CheckIn}/>
                                <Route exact path="/check-in/edit/:id"
                                       render={(props) => <CheckIn {...props} edit={true}/>}/>
                                <Route exact path="/user" component={User}/>
                                <Route exact path="/sign-in" component={SignIntoLyte}/>
                                <Route exact path="/checked-in" component={CheckedIn}/>
                                <Route exact path="/checked-in/status" component={CheckedInStatus}/>
                                <Route exact path="/duplicates/show" component={FindDuplicates}/>
                                <Route exact path="/reports" component={Analytics}/>
                                <Route exact path="/survey/:id" component={TakeSurvey}/>
                                <Route exact path="/surveys" component={Surveys}/>
                                <Route render={() => <h1>Page not found</h1>}/>
                            </Switch>
                        </ConfigProvider>
                    </div>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        )
    }

    isUserLoggedIn() {
        return httpRequest.getAuthToken();
    }
}

export default App;