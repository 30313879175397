import React, {useState} from "react";
import axios from "axios";
import {httpRequest} from '../services/httpRequest';
import {toast} from "react-toastify";

export default function CreateResource(props: any) {
    const [name, setName] = useState('');
    const save = () => {
        if (!name) {
            toast.error(`No service added. Please try again!`);
            return;
        }
        axios.post(`/api/v1/create-resource`,
            {resource: {name: name}},
            httpRequest.getHeaders()
        ).then((response) => {
            toast.info(`Service: ${name} successfully added. `);
            setName('')
            props.newResource(response.data)
        }).catch(error => httpRequest.errorHandler(error, props.history));
    }

    return (
        <div>
            <h5 className="card-header">Create new service</h5>
            <div className="card-body">
                <div className="input-group mb-3">
                    <input onChange={(e) => setName(e.target.value)}
                           value={name}
                           type='text'
                           className="form-control" placeholder='Name of Service'/>
                    <div className="input-group-append">
                        <button onClick={save}
                                className="btn btn-outline-primary" type="button">Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}