import React from 'react'
import ReactDOM from 'react-dom'
import App from '../components/App'
import {Route, HashRouter} from "react-router-dom";
import("../styles/application.scss");
import('bootstrap');

document.addEventListener('DOMContentLoaded', () => {
    const metaTag = document.createElement('meta');
    metaTag.name = "viewport"
    metaTag.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    document.getElementsByTagName('head')[0].appendChild(metaTag);
    ReactDOM.render(
        <HashRouter>
            <Route path="/" component={App} />
        </HashRouter>,
        document.body.appendChild(document.createElement('div')),
    )
});