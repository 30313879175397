import React, {useState} from "react"
import {Button, Card, Checkbox, Col, Input, List, Row, Typography} from "antd";
import dayjs from "dayjs";

export const SurveySend = ({respondents, setSurveyRespondents, surveyId}) => {
    const [filterValue, setFilterValue] = useState(null);

    const removeFromList = (email: any, checked: any) => {
        setSurveyRespondents(respondents.map((resp: any) => (resp.email === email ? {
            ...resp,
            disabled: !checked
        } : resp)))
    }

    const checkAllHandler = () => {
        const status = checkStatus();

        setSurveyRespondents(respondents.map((resp: any) => ({
            ...resp,
            disabled: status
        })))
    }

    const checkStatus = () => {
        return respondents.filter((a: any) => a.disabled).length === 0
    }

    const filterMatch = (item: any) => {
        let filterValueUpdated = filterValue ? filterValue.toLowerCase() : null;
        return filterValueUpdated ? (
            (
                item.name?.toLowerCase().match(filterValueUpdated)
                || item.firstName?.toLowerCase().match(filterValueUpdated)
                || item.lastName?.toLowerCase().match(filterValueUpdated) ||
                item.email?.toLowerCase().match(filterValueUpdated)
            ) ? item : null) : item
    }

    return (
        <Col xs={24}>
            <Row gutter={8}>
                <Col xs={12}>
                    <Button type={'primary'} ghost onClick={checkAllHandler}
                            className={'mb-2 text-right'}>{checkStatus() ? 'Un-Check' : 'Check'} All</Button>
                </Col>
                <Col xs={12}>
                    <Input placeholder={'Search for youth'} allowClear={true} value={filterValue} onChange={(e) => setFilterValue(e.target.value)}/>
                </Col>
            </Row>
            <List size={'small'}
                  itemLayout={'vertical'}
                  dataSource={respondents.filter((itz: any) => (filterValue ? filterMatch(itz) : itz)).filter((a: any) => a)}
                  bordered={false}
                  pagination={{size: 'small', position: 'bottom', align: 'end', total: respondents.length}}
                  renderItem={(item: any) => (
                      <List.Item>
                          <Card size={'small'}
                                title={`${item.name ? item.name : `${item.firstName} ${item.lastName}`} - ${item.email}`}
                                extra={<Checkbox onClick={(e: any) => removeFromList(item.email, e.target.checked)}
                                                 checked={!item.disabled}>Enabled</Checkbox>}>
                              {item.surveyResponses.filter((resp: any) => resp.survey_id === surveyId).filter((resp: any) => !(resp.expired)).map((response: any) =>
                                  (
                                      <Row key={response.id}>
                                          <Col xs={8}>Started: {response.started ? 'Yes' : 'No'}
                                          </Col>
                                          <Col xs={8}>
                                              Completed: {response.completed ? 'Yes' : 'No'}
                                          </Col>
                                          <Col xs={8}>
                                              Sent: {dayjs(response.created_at).format('MMMM, DD YYYY')}
                                          </Col>
                                      </Row>
                                  )
                              )}
                              {item.surveyResponses.filter((resp: any) => resp.survey_id === surveyId).length === 0 &&
                                  <Typography.Text>No data.</Typography.Text>
                              }
                          </Card>
                      </List.Item>
                  )
                  }
            />
        </Col>
    )
}