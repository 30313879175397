import React, {useEffect, useState} from 'react';
import axios from "axios";
import {httpRequest} from "../services/httpRequest";
import CreateResource from "../resources/CreateResource";
import Person from "../people/Person";
import ActivityLog from "../people/ActivityLog";
import Contact from "../contact/Contact";
import InfoSheet from "../people/InfoSheet";
import {PersonSurveys} from "../surveys/PersonSurveys";

export default function CheckIn(props: any) {
    const [activities, setActivities] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [contact, setContact] = useState(null);
    const [shouldShowNewResource, setShouldShowNewResource] = useState(false);
    const [id, setId] = useState(props.match ? props.match.params.id : null);
    const [edit, setEdit] = useState(props.edit);

    useEffect(() => {
        setEdit(props.edit);
        setId(props.match.params.id);
    }, [props.edit]);

    useEffect(() => {
        axios.get(`/api/v1/resource-list/list`,
            httpRequest.getHeaders())
            .then(response => {
                setResourceList(response.data);
            })
            .catch(error => httpRequest.errorHandler(error, props.history));

        axios.get(`/api/v1/activities/${id ? id : ''}`,
            httpRequest.getHeaders())
            .then(response => {
                setActivities(response.data);
            })
            .catch(error => httpRequest.errorHandler(error, props.history));
    }, []);

    useEffect(() => {
        if (edit) {
            axios.get(`/api/v1/contacts/${id}`,
                httpRequest.getHeaders())
                .then(response => {
                    setContact(response.data);
                })
                .catch(error => httpRequest.errorHandler(error, props.history));
        } else {
            setContact(null);
        }
    }, [edit]);

    function updateResourceList(updatedValue) {
        setShouldShowNewResource(!shouldShowNewResource);
        setResourceList([...resourceList, updatedValue]);
    }

    function updateActivitiesList(activity) {
        setActivities([...activities, activity]);
    }

    return (
        <div className="col-12 mt-2">
            <div className="row">
                <div className={edit ? 'col-sm-12' : 'col-md-8 col-sm-12'}>
                    <div className="card">
                        <Contact resourceList={resourceList}
                                 personId={id}
                                 contact={contact}
                                 setActivities={setActivities}
                                 updateActivitiesList={updateActivitiesList} />
                        {!edit && <ActivityLog activities={activities}/>}
                        {!edit && <InfoSheet personId={id}/>}
                        {!edit && <PersonSurveys history={props.history} personId={id} />}
                        { httpRequest.getUser()?.admin ? <CreateResource newResource={updateResourceList}/> : null}
                    </div>
                </div>
                {!edit && (<Person personId={id}/>)}
            </div>
        </div>
    )
}