import axios from "axios";
import {toast} from "react-toastify";

export const httpRequest = {
    getAuthToken() {
        return sessionStorage.getItem('authToken');
    },

    getUser() {
        return JSON.parse(sessionStorage.getItem('user'));
    },

    getBaseUrl() {
        return window.location.origin;
    },

    getHeaders() {
        return {
            headers: {
                'Authorization': this.getAuthToken()
            }
        }
    },

    logOut(history: any) {
        axios.delete(`/api/v1/sign_out`)
            .then(response => {
                sessionStorage.removeItem('authToken');
                sessionStorage.removeItem('user');
                history.push("/");
            })
            .catch(error => console.log(error));
    },

    errorHandler(error: any, history: any) {
        if (error.response && error.response.status === 401 && history.location.pathname !== '/login') {
            sessionStorage.clear();
            toast.error(error.response.data.error);
            history.push('/login');
        } else {
            toast.error(error.response.data.error);
        }
    }
};