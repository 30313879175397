import React, {useState} from "react";
import {format} from "date-fns";
import {httpRequest} from "../services/httpRequest";

import axios from "axios";
import {toast} from "react-toastify";

export default function BriefService(props: any) {
    const initialState = {
        service_date: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        duration: '',
        name: '',
        identity: '',
        age: '',
        referrals: '',
        notes: '',
        support_type: '',
        helped: '',
        support: []
    };
    const [service, setService] = useState(initialState);

    function updateField(e) {
        setService({
            ...service,
            [e.target.name]: e.target.value
        })
    }

    const submit = () => {
        axios.post('/api/v1/brief-service',
            {service}, httpRequest.getHeaders())
            .then((response) => {
                toast.success('Brief service successfully saved');
                props.history.push('/');
            }).catch(error => httpRequest.errorHandler(error, props.history))
    }

    const handleSelectChange = (e: any, type: string) => {
        const serviceSet = service[type];

        if (e.target.checked) {
            serviceSet.push(e.target.id);
        } else {
            serviceSet.splice(serviceSet.indexOf(e.target.id), 1)
        }

        setService({
            ...service,
            [type]: serviceSet
        })
    };
    return (
        <div className="col-12 mt-2">
            <div className="card">
                <h5 className='card-header'>Brief Service Form</h5>
                <div className={'card-body'}>
                    <div className="row">
                        <div className={'col-sm-12'}>
                            <div className={'card-body'}>
                                <div className={'row'}>
                                    <div className={'col-md-6 mb-1'}>
                                        <label className="form-label">Duration (in minutes)</label>
                                        <input value={service.duration}
                                               name={'duration'}
                                               onChange={updateField}
                                               className="form-control" aria-label={'Duration'}/>
                                    </div>
                                    <div className={'col-md-6 mb-1'}>
                                        <label className="form-label">Date of service</label>
                                        <input value={service.service_date}
                                               name={'service_date'}
                                               onChange={updateField}
                                               type='datetime-local'
                                               placeholder='Created at' className="form-control"
                                               aria-label="created at"/>
                                        <div className="input-group-append"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-sm-12'}>
                            <h6>Information</h6>
                            <div className={'card-body'}>
                                <div className={'row'}>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'support_type'}>Type</label>
                                        <select className="form-control" name={'support_type'}
                                                onChange={(e) => updateField(e)}>
                                            <option id={'choose'} selected disabled hidden>Select an option</option>
                                            {[
                                                {
                                                    id: 'email',
                                                    display: 'Referral Support/Email'
                                                },
                                                {
                                                    id: 'social',
                                                    display: 'Referral Support/Social Media'
                                                },
                                                {
                                                    id: 'telephone',
                                                    display: 'Referral Support/Telephone'
                                                },
                                                {
                                                    id: 'walk-in',
                                                    display: 'Referral Support/Walk In'
                                                }
                                            ].map((option, i) => {
                                                return (<option value={option.id} key={i}>{option.display}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'helped'}>Who is the person you helped?</label>
                                        <select className="form-control" name={'helped'}
                                                onChange={(e) => updateField(e)}>
                                            <option id={'choose'} selected disabled hidden>Select an option</option>
                                            {[
                                                {
                                                    id: 'youth',
                                                    display: 'Youth in need of services'
                                                },
                                                {
                                                    id: 'adult',
                                                    display: 'Adult in need of services'
                                                },
                                                {
                                                    id: 'parent-guardian',
                                                    display: 'Parent/Guardian'
                                                },
                                                {
                                                    id: 'friend-partner',
                                                    display: 'Friend/Partner'
                                                },
                                                {
                                                    id: 'school-employee',
                                                    display: 'School Employee'
                                                },
                                                {
                                                    id: 'relative',
                                                    display: 'Relative'
                                                },
                                                {
                                                    id: 'other-supportive',
                                                    display: 'Other supportive individual'
                                                },
                                                {
                                                    id: 'other-provider',
                                                    display: 'Other Provider'
                                                }
                                            ].map((option, i) => {
                                                return (<option key={i} value={option.id}>{option.display}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'person-helped'}>Individual's Name</label>
                                        <input value={service.name}
                                               name={'name'}
                                               onChange={updateField}
                                               className="form-control" aria-label={'Name'}/>
                                    </div>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'gender-identity'}>Gender identity (of person needing
                                            support)</label>
                                        <input value={service.identity}
                                               name={'identity'}
                                               onChange={updateField}
                                               className="form-control" aria-label={'Gender Identity'}/>
                                    </div>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'age'}>Age (of person needing support)</label>
                                        <input value={service.age}
                                               name={'age'}
                                               onChange={updateField}
                                               className="form-control" aria-label={'Age of Person Needing Support'}/>
                                    </div>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'referrals'}>Referrals Provided</label>
                                        <input value={service.referrals}
                                               name={'referrals'}
                                               onChange={updateField}
                                               className="form-control" aria-label={'Referrals Provided'}/>
                                    </div>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'referrals'}>Type of support provided (includes
                                            referrals)</label>
                                        {[
                                            {
                                                name: 'housing',
                                                value: 'Housing support'
                                            },
                                            {
                                                name: 'education',
                                                value: 'Education support'
                                            },
                                            {
                                                name: 'employment',
                                                value: 'Employment support'
                                            },
                                            {
                                                name: 'basic-needs',
                                                value: 'Basic needs support'
                                            },
                                            {
                                                name: 'physical-health',
                                                value: 'Physical health support'
                                            },
                                            {
                                                name: 'personal-id',
                                                value: 'Personal identification'
                                            },
                                            {
                                                name: 'benefits',
                                                value: 'Benefits enrollment'
                                            },
                                            {
                                                name: 'legal',
                                                value: 'Legal support'
                                            },
                                            {
                                                name: 'parenting',
                                                value: 'Parenting support'
                                            },
                                            {
                                                name: 'general',
                                                value: 'General support'
                                            },
                                            {
                                                name: 'mental-health',
                                                value: 'Mental health support'
                                            },
                                            {
                                                name: 'recreation',
                                                value: 'Recreation support'
                                            },
                                            {
                                                name: 'financial-assistance',
                                                value: 'Financial Assistance'
                                            }
                                        ].map((item) => {
                                            return (
                                                <div className="form-check" key={item.name}>
                                                    <input className="form-check-input"
                                                           name={'support'}
                                                           onChange={(e) => handleSelectChange(e, 'support')}
                                                           type="checkBox" id={item.name}/>
                                                    <label className="form-check-label" htmlFor="support">
                                                        {item.value}
                                                    </label>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    <div className={'col-md-6 mb-3'}>
                                        <label htmlFor={'notes'}>Additional Info/Notes</label>
                                        <textarea value={service.notes}
                                                  name={'notes'}
                                                  rows={10}
                                                  onChange={updateField}
                                                  className="form-control" aria-label={'Additional Info/Notes'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-sm-12'}>
                            <button className={'btn btn-primary btn-sm btn-block'} onClick={submit}>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}